
export default {
  name: 'Link',

  props: {
    to: {
      type: [String, Object],
      default: null
    },

    // Inline auto sets underline
    type: {
      type: String,
      default: 'default',
      validator: prop => ['default', 'inline'].includes(prop)
    },

    underline: {
      type: Boolean,
      default: false
    },

    openInNewTab: {
      type: Boolean,
      default: false
    },

    openInAppBrowser: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    // Overrides prefixIcon
    prefixText: {
      type: String,
      default: null
    },

    prefixIcon: {
      type: Object,
      default: null
    },

    suffixText: {
      type: String,
      default: null
    },

    suffixIcon: {
      type: Object,
      default: null
    },

    inheritColor: {
      type: Boolean,
      default: false
    },

    exactRouteMatching: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    compClass() {
      return [
        {
          'flex items-center': this.type !== 'inline',
          'inline-block': this.type === 'inline',
          'text-link hover:link-hover': !this.inheritColor,
          'text-current': this.inheritColor
        },
        'cursor-pointer'
      ]
    },

    compTo() {
      if (!this.to) {
        return null
      }

      return this.compIsDeepLink || this.compOpenInNewTab ? this.to : this.localePath(this.to)
    },

    compOpenInNewTab() {
      return this.openInNewTab || (typeof this.to === 'string' && this.to.includes('https://'))
    },

    compIsDeepLink() {
      return typeof this.to === 'string' && this.to && (this.to.includes('mailto:') || this.to.includes('tel:'))
    },

    compHasPrefix() {
      return !!this.prefixIcon || !!this.prefixText || !!this.$slots.prefix
    },

    compHasSuffix() {
      return !!this.suffixIcon || !!this.suffixText || !!this.$slots.suffix
    }
  },

  methods: {
    openNewWindow() {
      this.$emit('click')

      this.$openWindow(this.to, this.openInAppBrowser)
    }
  }
}
