
import { mapGetters } from 'vuex'

export default {
  name: 'MobileDropdownOverlay',

  computed: {
    ...mapGetters({
      compShouldShowOverlay: 'dropdownOverlay/shouldShowDropdown',
      compDropdownOptions: 'dropdownOverlay/dropdownOptions'
    })
  },

  methods: {
    handleClose() {
      this.$store.dispatch('dropdownOverlay/clearDropdownOptions')
    }
  }
}
