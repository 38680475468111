
export default {
  name: 'AppLoader',

  data() {
    return {
      fetchIpApi: new this.$ApiModel(),
      ipInformation: {}
    }
  },

  computed: {
    isVirginMediaCustomer() {
      return this.ipInformation?.isp?.toLowerCase().includes('virgin media')
    }
  },

  mounted() {
    if (this.$app.isNative) {
      this.$store.dispatch('device/changeStatusBarColorPrimary')

      this.fetchIpInformation()
    }
  },

  methods: {
    retryConnection() {
      this.$store.dispatch('app/fetchStatus')

      this.$analytics.addEvent('Clicked retry connection from splash screen')
    },

    async fetchIpInformation() {
      const { response } = await this.$api.raw(this.fetchIpApi).get('http://ip-api.com/json/')

      this.$set(this, 'ipInformation', response.data)
    }
  }
}
