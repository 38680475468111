// This plugin monitors user activity and fires an array of callbacks with
// indivudual minimum intervals ncreasingly less frequently until user becomes active.

import { throttle } from 'lodash'

import { isNative } from '~/plugins/native/capacitor'

// Config vars
const defaultFetchInterval = 30 * 1000
const listenEvents = ['click', 'mousemove', 'visibilitychange']
const maxFetchInterval = 60 * 60 * 1000

// Setup
let callbackArray = []
let userActive = false
let hasActiveTimers = false

export default function ({ store, $app, $log }, inject) {
  function setListeners() {
    // Set listeners
    listenEvents.forEach(eventName => {
      document.addEventListener(eventName, throttle(setActive, defaultFetchInterval))
    })

    if (isNative) {
      const { App } = require('@capacitor/app')

      App.addListener('appStateChange', throttle(setActive, defaultFetchInterval))
    }
  }

  function removeListeners() {
    listenEvents.forEach(eventName => {
      document.removeEventListener(eventName, throttle(setActive, defaultFetchInterval))
    })

    if (isNative) {
      const { App } = require('@capacitor/app')

      App.removeListener('appStateChange', throttle(setActive, defaultFetchInterval))
    }
  }

  // Respond to activity & state changes
  function setActive({ isActive = true }) {
    // Let's not do anything if app is not in focus
    if ((isNative && !isActive) || (!isNative && document.visibilityState === 'hidden')) {
      return
    }

    userActive = true

    // If user has been inactive fire update immediately and reset timers

    if (callbackArray.length) {
      callbackArray.forEach(callback => {
        if (callback.interval > callback.originalInterval) {
          callback.callback()
          callback.interval = callback.originalInterval
          resetTimer(callback)
        }
      })
    }
  }

  function setUpdateTimer(callback) {
    callback.timer = setInterval(() => {
      handleInterval(callback)
    }, callback.interval)
  }

  function resetTimer(callback) {
    clearInterval(callback.timer)
    setUpdateTimer(callback)
  }

  // To run on each interval
  function handleInterval(callback) {
    callback.callback()

    // If user is inactive increment refresh interval
    if (!userActive && callback.interval < maxFetchInterval) {
      callback.interval += callback.originalInterval
      resetTimer(callback)
    } else {
      userActive = false
    }
  }

  function clearCallbacks(shouldPreserveStatus = true) {
    callbackArray.forEach(callback => {
      clearInterval(callback.timer)
    })

    callbackArray = []

    // Leave statusrefresh
    if (shouldPreserveStatus) {
      const statusRefresh = () => {
        store.dispatch('app/fetchStatus')
      }

      activityRefresh.addCallback(statusRefresh)
    } else {
      removeListeners()
    }
  }

  const activityRefresh = {
    // Adds a callback, initialise if unset.
    addCallback(callback, interval = defaultFetchInterval) {
      if (
        typeof callback === 'function' &&
        !callbackArray.find(callbackObject => callbackObject.callback === callback)
      ) {
        const callbackObject = {
          callback,
          interval,
          originalInterval: interval,
          timer: 0
        }

        setUpdateTimer(callbackObject)

        callbackArray.push(callbackObject)
      } else {
        $log.debug(
          typeof callback === 'function'
            ? `ActivityRefresh: Callback already exists ${callback.name}`
            : 'ActivityRefresh: Callback is not a function.'
        )
      }

      if (!hasActiveTimers) {
        setListeners()
        hasActiveTimers = true
      }
    },

    removeCallback(callback) {
      const callbackIndex = callbackArray.indexOf(
        callbackArray.find(callbackObject => callbackObject.callback === callback)
      )

      if (callbackIndex > -1) {
        clearInterval(callbackArray[callbackIndex].timer)
        callbackArray.splice(callbackIndex, 1)

        if (!callbackArray.length) {
          hasActiveTimers = false
          removeListeners()
        }
      }
    },

    clear(shouldPreserveStatus = true) {
      clearCallbacks(shouldPreserveStatus)
    }
  }

  inject('activityRefresh', activityRefresh)
}
