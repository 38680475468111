import { get } from 'lodash'

import { truncateObjectStrings } from '~/lib/transform-data-format'
import { ApiModel } from '~/plugins/api/model'

const initialState = () => {
  return {
    queue: [],
    guestId: null,
    sendLogsApiModel: new ApiModel(),
    fetchLogTagsApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  getTags: state => {
    return get(state.fetchLogTagsApi, 'response.data', [])
  }
}

export const actions = {
  async sendLogs({ state, commit, rootGetters }) {
    if (state.queue.length === 0) {
      return false
    }

    const maxBatchSize = 15

    // Process all logs in the queue and remove them
    const batch = [...state.queue]

    const limitedBatch = batch.slice(0, maxBatchSize)

    commit('emptyQueue')

    try {
      await this.$api.system(state.sendLogsApiModel).useStorePath('logs.sendLogsApiModel').saveLogs(limitedBatch)
    } catch (error) {
      // Can't $log.debug here as we could create an inifite loop of sending failed logs
      console.error('Error sending analytics logs', error)

      // Re-add failed logs back into the queue
      commit('addToQueue', limitedBatch)
    } finally {
      // If there were more logs left in the batch, add them back to the queue
      if (batch.length > maxBatchSize) {
        commit('addToQueue', batch.slice(maxBatchSize))
      }
    }
  }
}

export const mutations = {
  addToQueue(state, log) {
    const reducedLog = truncateObjectStrings(log)

    if (Array.isArray(reducedLog)) {
      state.queue = [...reducedLog, ...state.queue]
    } else {
      state.queue.push(reducedLog)
    }
  },

  emptyQueue(state) {
    state.queue.splice(0)
  },

  setGuestId(state, guestId) {
    console.log('GuestId', guestId)
    state.guestId = guestId
  },

  reset(state) {
    this.$log.debug('Resetting logs module')

    Object.assign(state, initialState())

    this.$log.resetGuestId()
  }
}
