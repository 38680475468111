import { PlatformApi } from '~/services/api/platform'

export class FileService extends PlatformApi {
  async getSignedKey(fileData) {
    const request = await this.post(`/file/signed-url`, fileData)

    return request.response.data
  }

  async deleteFile(fileId) {
    await this.delete(`/file/${fileId}`)
  }
}
