export const syncStatus = {
  0: 'UP_TO_DATE',
  1: 'UPDATE_INSTALLED',
  2: 'UPDATE_IGNORED',
  3: 'UPDATE_FAILED',
  4: 'UPDATE_IN_PROGRESS',
  5: 'UPDATE_CHECKING',
  6: 'UPDATE_AWAITING_USER_ACTION',
  7: 'UPDATE_DOWNLOADING',
  8: 'UPDATE_INSTALLING'
}

// Apple doesn't let you show update progress to the user
export const syncStatusDiscrete = {
  0: 'UTD',
  1: 'INS',
  2: 'IGN',
  3: 'UFD',
  4: 'INP',
  5: 'CHK',
  6: 'AUA',
  7: 'UDL',
  8: 'UIN'
}
