import Vue from 'vue'

export default ({ app, $log }, inject) => {
  const elementReady = {
    // Accepts ref key string and context (this)
    ref(ref, context, poll = 200) {
      if (ref && context && typeof ref === 'string' && context instanceof Vue) {
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (context.$refs[ref]) {
              clearInterval(interval)
              resolve(context.$refs[ref])
            }
          }, poll)
        })
      } else {
        throw new Error('Must pass ref as string and component context as second param (this).')
      }
    },

    selector(selectors, poll = 200) {
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          const result = document.querySelector(selectors)

          if (result) {
            clearInterval(interval)
            resolve(result)
          }
        }, poll)
      })
    }
  }

  inject('elementReady', elementReady)
}
