
import { faArrowRight, faPencilAlt, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { mapGetters } from 'vuex'

export default {
  name: 'BannerGlobal',

  props: {
    forceData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isHidden: false,
      faArrowRight,
      faPencilAlt,
      faTimes
    }
  },

  computed: {
    ...mapGetters({
      globalBanner: 'app/globalBanner'
    }),

    compGlobalBanner() {
      return 'body' in this.forceData ? this.forceData : this.globalBanner
    },

    compIsForcedData() {
      return 'body' in this.forceData
    },

    compIsVisible() {
      if (this.compIsForcedData) {
        return true
      }

      if (!(this.compGlobalBanner?.body && this.compGlobalBanner?.enabled)) {
        return false
      }

      if (this.$route.fullPath.includes('/checkout')) {
        return false
      }

      if (this.$app.isStaff) {
        return true
      }

      if (this.isHidden) {
        return false
      }

      if (this.compGlobalBanner.visibleTo === 'LOGGED_IN' && !this.$app.isAuth) {
        return false
      }

      if (this.compGlobalBanner.visibleTo === 'LOGGED_OUT' && this.$app.isAuth) {
        return false
      }

      return true
    },

    compStyle() {
      const customStyle = {}

      if (this.compGlobalBanner?.backgroundColor) {
        customStyle.backgroundColor = this.compGlobalBanner.backgroundColor
        customStyle.borderColor = this.compGlobalBanner.backgroundColor
      }

      if (this.compGlobalBanner?.textColor) {
        customStyle.color = this.compGlobalBanner.textColor
      }

      return customStyle
    },

    compHasCustomStyle() {
      return this.compGlobalBanner?.backgroundColor || this.compGlobalBanner?.textColor
    }
  },

  created() {
    if (this.$cookies.get('hide-global-banner')) {
      this.isHidden = true
    }
  },

  methods: {
    hideBanner() {
      this.$log.debug('Hiding global banner')

      this.$cookies.set('hide-global-banner', true, {
        expires: this.$date().add(7, 'day').toDate(),
        path: '/',
        secure: false
      })

      this.isHidden = true
    }
  }
}
