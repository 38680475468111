import Vue from 'vue'

export function ApiModel(existingModel) {
  return Vue.observable({
    isLoading: false,
    hasError: false,
    uploadProgress: 0,
    model: Array.isArray(existingModel) ? [...existingModel] : { ...existingModel },
    response: {
      code: null,
      status: null,
      message: null,
      data: [],
      pagination: {},
      errors: []
    },
    // Used for throttling requests per method
    timeMethodLastCalled: {
      GET: 0,
      POST: 0,
      DELETE: 0,
      PATCH: 0,
      PUT: 0,
      HEAD: 0
    }
  })
}

export default function (context, inject) {
  // Inject to context as $ApiModel
  inject('ApiModel', ApiModel)
}
