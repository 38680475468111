import { isNative } from '~/plugins/native/capacitor'

// Handle auto reloading the page after a route change if web version is out of date
export default function ({ app, store, $cookies, $dateNew, $log }, inject) {
  const autoReload = {
    latestVersion: 0,
    shouldReloadOnRouteChange: false,

    stringVersionToNumber(version) {
      return Number(version.replaceAll('.', ''))
    },

    setLatestVersion(version) {
      this.latestVersion = this.stringVersionToNumber(version)

      this.compareVersions()
    },

    compareVersions() {
      if (isNative) {
        return false
      }

      const app = store.getters['app/getApp']

      if (app.isLocal) {
        return
      }

      const currentVersion = this.stringVersionToNumber(app.version)

      const lastReloadTime = $cookies.get('last-reload-at') || 0
      const minutesSinceLastReload = $dateNew.now().diff($dateNew.fromMillis(lastReloadTime), 'minutes').minutes

      // If there is a bug in our code or the reported latest version let's
      // avoid force reloading more than once every 5 minutes
      if (minutesSinceLastReload >= 5 && currentVersion < this.latestVersion) {
        $log.debug(
          'New web version available, reloading next route change',
          `${currentVersion} -> ${this.latestVersion}`
        )

        this.shouldReloadOnRouteChange = true
      }
    },

    setLastReloadTime() {
      $cookies.set('last-reload-at', $dateNew.now().toMillis())
    },

    reloadPage() {
      this.setLastReloadTime()

      $log.debug('New version available, reloading page')

      window.location.reload()
    }
  }

  app.router.afterEach((to, from) => {
    if (autoReload.shouldReloadOnRouteChange) {
      autoReload.reloadPage()
    }
  })

  // Inject to context as $autoReload
  inject('autoReload', autoReload)
}
