
import { faBars, faCircleUser, faMobile } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'Brochureware',

  props: {
    hero: {
      type: Object,
      default: () => ({
        title: null,
        image: null,
        alt: null
      })
    },

    hasContentPadding: {
      type: Boolean,
      default: true
    },

    isNativeAppPromoVisible: {
      type: Boolean,
      default: true
    },

    bookNowLink: {
      type: String,
      default: '/book'
    },

    basketVisible: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      faMobile,
      faCircleUser,
      faBars
    }
  },

  methods: {
    showBasket() {
      this.$modal.open('book/basket')
    }
  }
}
