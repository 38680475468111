// We need to be able to upload files outside of the FileUploadCore component
// For example when uploading offline field data in the offline app
export default function ({ $log, $api, app }, inject) {
  const file = {
    async upload(file, category) {
      try {
        $log.debug('Starting file upload', {
          name: file.name,
          type: file.mimeType,
          size: file.sizeBytes,
          category,
          originalSize: file.originalSizeHuman
        })

        file.errorMessage = null

        const fileData = {
          name: file.name,
          mimeType: file.mimeType,
          sizeBytes: file.sizeBytes,
          category
        }

        // First we need a signed URL from the API in order to directly upload to S3
        const { preSignedUrl, publicUrl, fileId } = await $api.file(file.signedURLApi).getSignedKey(fileData)

        if (!preSignedUrl) {
          throw new Error('No preSignedUrl URL returned')
        }

        let fileBinary = file.rawFile
        const headers = { 'Content-Type': file.mimeType }

        // If we have base64 data then it's likely an image resized, or coming from native app file picker
        if (file.base64) {
          // Remove the "data:image/jpeg;base64," from the base64 string before loading into the buffer
          fileBinary = Buffer.from(file.base64.replace(/^.+?(;base64),/, ''), 'base64')

          headers['Content-Encoding'] = 'base64'
        }

        // Now we upload directly to S3 using our temporary signed URL
        await $api.raw(file.uploadApi).put(preSignedUrl, fileBinary, {
          headers
        })

        // Success! update data that can now be used by client
        file.publicUrl = publicUrl
        file.fileId = fileId

        $log.debug('File finished uploading', file.name)
      } catch (error) {
        $log.error(`Error uploading file ${file.name}`, error)

        file.errorMessage = app.i18n.t('uploadFailedTryAgain')

        throw new Error(error)
      }
    },

    async delete(file) {
      return await $api.file(file.deleteApi).deleteFile(file.fileId)
    }
  }

  // Inject to context as $file
  inject('file', file)
}
