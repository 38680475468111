
export default {
  name: 'CookieBanner',

  data() {
    return {
      isVisible: false
    }
  },

  created() {
    if (typeof this.$cookies.get('cookie-consent') === 'undefined' && !this.$app.isNative) {
      this.isVisible = true
    }
  },

  methods: {
    rejectCookies() {
      this.setCookieConsent(false)
    },

    acceptCookies() {
      this.setCookieConsent(true)
    },

    setCookieConsent(accepted) {
      this.isVisible = false

      this.$cookies.set('cookie-consent', accepted, {
        path: '/',
        expires: this.$date().add(365, 'day').toDate()
      })

      this.$analytics.addEvent('cookie-consent', {
        accepted
      })
    }
  }
}
