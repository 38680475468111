import { detect as detectBrowser } from 'detect-browser'

import appVersion from '~/lib/appVersion'
import { primary } from '~/lib/enums/color.config'
import { isNative, platform } from '~/plugins/native/capacitor'

const initialState = () => {
  return {
    network: {
      isOnline: true
    },
    details: {
      native: {},
      web: {}
    },
    bundleVersion: appVersion.default,
    hasHiddenSplash: false,
    statusBarColor: primary.DEFAULT,
    isStatusBarVisible: true,
    isNativeKeyboardVisible: false
  }
}

export const state = () => initialState()

export const getters = {
  isNativeKeyboardVisible(state) {
    return state.isNativeKeyboardVisible
  },

  isOnline(state) {
    return state.network.isOnline
  }
}

export const actions = {
  async getInfo({ commit }) {
    if (isNative) {
      const { Device } = await import('@capacitor/device')
      const { App } = await import('@capacitor/app')

      const languageCode = await Device.getLanguageCode()
      const deviceInfo = await Device.getInfo()
      const appInfo = await App.getInfo()

      commit('setDetails', {
        key: 'native',
        deviceInfo: {
          ...deviceInfo,
          app: { ...appInfo },
          locale: languageCode.value
        }
      })
    } else {
      // Work out if we need to show the outdated browser message
      const browser = detectBrowser()

      if (browser && browser.name) {
        let outOfDate = false
        const version = parseInt(browser.version)

        // IE 10 and below are already take care of by index.html blocker
        if (browser.name === 'ie' && version === 11) {
          outOfDate = true
        }

        if (browser.name === 'chrome' && version <= 60) {
          outOfDate = true
        }

        if (browser.name === 'edge' && version <= 20) {
          outOfDate = true
        }

        const browserInfo = {
          ...browser,
          outOfDate
        }

        commit('setDetails', {
          key: 'web',
          deviceInfo: {
            ...browserInfo
          }
        })
      }
    }
  },

  async changeStatusBarColor({ commit }, { hex, isDark }) {
    if (platform === 'ios') {
      commit('setStatusBarColor', hex)

      const { StatusBar, StatusBarStyle } = await import('@capacitor/status-bar')

      StatusBar.setStyle({ style: isDark ? StatusBarStyle.Dark : StatusBarStyle.Light })
    }
  },

  async changeStatusBarColorPrimary({ dispatch }) {
    if (platform === 'ios') {
      await dispatch('changeStatusBarColor', { hex: primary.DEFAULT, isDark: true })
    }
  },

  async changeStatusBarColorWhite({ dispatch }) {
    if (platform === 'ios') {
      await dispatch('changeStatusBarColor', { hex: '#FFFFFF', isDark: true })
    }
  }
}

export const mutations = {
  setHasHiddenSplash(state) {
    state.hasHiddenSplash = true
  },

  setDetails(state, { deviceInfo, key }) {
    state.details[key] = deviceInfo
  },

  setIsOnline(state, isOnline) {
    state.network.isOnline = isOnline
  },

  setNativeKeyboardVisible(state, isVisible) {
    state.isNativeKeyboardVisible = isVisible
  },

  setStatusBarColor(state, hex) {
    state.statusBarColor = hex
  },

  setStatusBarVisibility(state, isVisible) {
    state.isStatusBarVisible = isVisible
  },

  setBundleVersion(state, version) {
    state.bundleVersion = version
  }
}
