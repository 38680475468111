import { configure, extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  digits,
  email,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  required,
  required_if as requiredIf
} from 'vee-validate/dist/rules'
import Vue from 'vue'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default function ({ app, $log }) {
  // All our form field names must be translated
  configure({
    defaultMessage: (field, values) => {
      // Override the field name.
      values._field_ = app.i18n.t(`formFields.${field}`)
      return app.i18n.t(`formValidation.${values._rule_}`, values)
    }
  })

  // https://baianat.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
  setInteractionMode('eager-change', ({ errors, value }) => {
    if (errors.length) {
      return {
        on: ['input', 'change']
      }
    }

    // If the user hasn't entered a value yet then don't error on blur.
    // This is useful when using autofocus on a modal when the user
    // immediately clicks close modal, we won't want an error to show
    if (!value) {
      return {
        on: ['change']
      }
    } else {
      return {
        on: ['change', 'blur']
      }
    }
  })

  // Rules to use globally
  extend('required', required)
  extend('requiredIf', requiredIf)
  extend('email', email)
  extend('min', min)
  extend('max', max)
  extend('minValue', minValue)
  extend('maxValue', maxValue)
  extend('maxLoad', maxValue)
  extend('digits', digits)

  extend('acceptField', {
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseAcceptTheField', { fieldName: app.i18n.t(`formFields.${fieldName}`) })
    },
    validate: value => {
      return value === true
    }
  })

  extend('nonZeroValue', {
    message: (fieldName, placeholders) => {
      // const fieldTranslation = app.i18n.t(`formFields.${fieldName}`)

      // console.log('fieldname', fieldName)
      // console.log('fieldTranslation', fieldTranslation)

      return app.i18n.t('pleaseEnterAValue', { fieldName })
    },
    validate: value => {
      value = parseFloat(value)

      if (!value || value === 0) {
        return false
      }

      return true
    }
  })

  extend('validUrl', {
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseEnterValidURL', { fieldName: app.i18n.t(`formFields.${fieldName}`) })
    },
    validate: value => {
      return value.startsWith('http://') || value.startsWith('https://')
    }
  })

  extend('requiredIf', {
    ...requiredIf,
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseCompleteFieldIf', {
        field1: app.i18n.t(`formFields.${fieldName}`),
        field2: app.i18n.t(`formFields.${placeholders.target}`)
      })
    }
  })

  extend('requiredIfTargetEmpty', {
    ...requiredIf,
    validate: (value, args) => {
      const targetValue = args.target

      return Boolean(targetValue || value)
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('pleaseFillInEitherField', {
        field1: app.i18n.t(`formFields.${placeholders.target}`),
        field2: app.i18n.t(`formFields.${fieldName}`)
      })
    }
  })
}
