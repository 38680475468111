const colors = require('tailwindcss/colors')

const primary = {
  50: '#e7ecf1',
  100: '#d5dbe2',
  200: '#c3cad4',
  300: '#b4b9c5',
  400: '#9197aa',
  500: '#74798f',
  DEFAULT: '#454659',
  600: '#2d3043',
  700: '#1a1f2e',
  800: '#0c1018',
  900: '#010203'
}

const secondary = {
  DEFAULT: '#0D6E9C',
  50: '#dfe9f1',
  100: '#A6DDF8',
  200: '#6EC8F3',
  300: '#35B3EE',
  400: '#1296D4',
  500: '#0D6E9C',
  600: '#0B5D84',
  700: '#094D6D',
  800: '#073C55',
  900: '#052C3E'
}

const highlight = '#eae5bf'

const tailwindColorConfig = {
  body: {
    DEFAULT: primary.DEFAULT
  },
  highlight,
  'highlight-lighter': '#fbfaf2',
  error: colors.red[700],
  success: colors.green[600],
  active: highlight,
  link: '#0075B6',
  'link-hover': '#073d59',
  label: colors.gray[800],
  light: colors.gray[500],
  lighter: colors.gray[400],
  'panel-bg': colors.gray[50],
  'panel-border': colors.gray[200],
  overlay: 'rgba(7, 17, 81, 0.4)',
  hover: colors.gray[50],
  primary,
  'primary-hover': primary[700],
  secondary
}

module.exports = {
  primary,
  highlight,
  tailwindColorConfig
}
