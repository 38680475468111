import { isNative } from '~/plugins/native/capacitor'

export default function ({ $log }, inject) {
  const openWindow = async (url, openInNativeAppBrowser = false) => {
    // If using native and we want to open with the overlayed in-app browser
    if (openInNativeAppBrowser && isNative) {
      const { Browser } = await import('@capacitor/browser')

      $log.debug('Opening native window', url)

      await Browser.open({ url })

      return Browser
    }

    $log.debug('Opening window', url)

    // If native switch to and open with system browser, otherwise new tab
    const target = isNative ? null : '_blank'

    window.open(url, target)
  }

  // Inject to context as $openWindow
  inject('openWindow', openWindow)
}
