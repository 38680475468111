import { PlatformApi } from '~/services/api/platform'

export class ReportingService extends PlatformApi {
  async getAdminStats() {
    return await this.get(`/reporting/admin/stats`)
  }

  async getAdminStatsAllTime() {
    return await this.get(`/reporting/admin/stats/all`)
  }

  async getAdminPaddockStats() {
    return await this.get(`/reporting/admin/stats/paddocks`)
  }

  async getAdminUserStats() {
    return await this.get(`/reporting/admin/stats/users`)
  }

  async getAdminRealtimeLogs() {
    return await this.get(`/reporting/admin/realtime-logs`)
  }

  async getAdminRevenue() {
    return await this.get(`/reporting/admin/revenue`)
  }

  async getAdminRevenueAll() {
    return await this.get(`/reporting/admin/revenue/all`)
  }
}
