import { render, staticRenderFns } from "./Heading.vue?vue&type=template&id=07b27c66&scoped=true"
import script from "./Heading.vue?vue&type=script&lang=js"
export * from "./Heading.vue?vue&type=script&lang=js"
import style0 from "./Heading.vue?vue&type=style&index=0&id=07b27c66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b27c66",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/image/Img.vue').default})
