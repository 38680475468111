
import { colorProp as color } from '~/lib/utility/colors'

export default {
  name: 'BadgeCount',

  props: {
    color,
    total: {
      type: Number,
      default: 0
    },

    isAlwaysPill: {
      type: Boolean,
      default: true
    },

    showIfZero: {
      type: Boolean,
      default: false
    },

    isUnread: {
      type: Boolean,
      default: false
    },

    isOutline: {
      type: Boolean,
      default: true
    }
  }
}
