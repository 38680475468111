import { render, staticRenderFns } from "./PurchasingBlocker.vue?vue&type=template&id=1b09391e"
import script from "./PurchasingBlocker.vue?vue&type=script&lang=js"
export * from "./PurchasingBlocker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/home/runner/work/platform/platform/packages/client/src/components/utils/loader/Loader.vue').default})
