import { PlatformApi } from '~/services/api/platform'

export class GeoService extends PlatformApi {
  async searchAddress(searchQuery) {
    return await this.get(`/geo/address/search/${searchQuery}`)
  }

  async getFullAddressFromId(addressId) {
    return await this.get(`/geo/address/${addressId}`)
  }

  async getStaticMapImage({ tileStyleSlug, geoJson, viewParams, width, height, retinaString }) {
    return await this.get(
      `/geo/map/image/${tileStyleSlug}/${geoJson}/${viewParams}/${width}x${height}${retinaString}`
    )
  }

  async getDirections({ startCoordinates, arriveAt, paddockId }) {
    return await this.get('/geo/directions', {
      startCoordinates,
      arriveAt,
      paddockId
    })
  }

  async lookupPostcode(postcode) {
    return await this.get(`/geo/postcode/${postcode}`)
  }

  async getBookingWeather(bookingId) {
    return await this.get(`/geo/weather/${bookingId}`)
  }
}
