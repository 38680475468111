import { OAuth2Client } from '@byteowls/capacitor-oauth2'
import { FacebookLogin } from '@capacitor-community/facebook-login'

import { isNative } from '~/plugins/native/capacitor'

let appleIDSignOnSuccessListener
let appleIDSignInOnFailureListener

export default function ({ $log, store, $notify, $redirect }, inject) {
  const socialLogin = {
    google: returnUrl => {
      OAuth2Client.authenticate({
        authorizationBaseUrl: 'https://accounts.google.com/o/oauth2/auth',
        accessTokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
        scope: 'email profile',
        resourceUrl: 'https://www.googleapis.com/userinfo/v2/me',
        web: {
          appId: '90742648603-35laskhclvqlbnh7d4qadh2etn617la1.apps.googleusercontent.com',
          responseType: 'token', // implicit flow
          accessTokenEndpoint: '', // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
          redirectUrl: window.location.href
        },
        android: {
          appId: process.env.OAUTH_GOOGLE_ANDROID_CLIENT_ID,
          responseType: 'code', // if you configured a android app in google dev console the value must be "code"
          redirectUrl: `${process.env.NATIVE_APP_ID}:/` // package name from google dev console
        },
        ios: {
          appId: process.env.OAUTH_GOOGLE_IOS_CLIENT_ID,
          responseType: 'code', // if you configured a ios app in google dev console the value must be "code"
          redirectUrl: `${process.env.NATIVE_APP_ID}:/` // Bundle ID from google dev console
        }
      })
        .then(resourceUrlResponse => {
          $log.debug('Google login success')

          const accessToken = resourceUrlResponse.access_token

          if (accessToken) {
            $redirect.to('/auth/social-login')

            store.dispatch('auth/handleSocialCallback', {
              provider: 'google',
              accessToken,
              returnUrl
            })
          }
        })
        .catch(error => {
          $log.debug('Google login error', error)
          $notify.error('Failed to login, please try again')
        })
    },

    facebook: async returnUrl => {
      try {
        const result = await FacebookLogin.login({ permissions: ['email'] })

        if (result.accessToken) {
          $redirect.to('/auth/social-login')

          store.dispatch('auth/handleSocialCallback', {
            provider: 'facebook',
            accessToken: result.accessToken.token,
            returnUrl
          })
        } else {
          throw new Error('No access token in response')
        }
      } catch (error) {
        $log.debug('Facebook login error', error)
        $notify.error('Failed to login, please try again')
      }
    },

    apple: async returnUrl => {
      if (isNative) {
        try {
          const resourceUrlResponse = await OAuth2Client.authenticate({
            appId: process.env.NATIVE_APP_ID,
            logsEnabled: true,
            authorizationBaseUrl: 'https://appleid.apple.com/auth/authorize',
            ios: {
              siwaUseScope: true,
              scope: 'name email'
            }
          })

          $log.debug('Apple login success')

          console.log('apple response', resourceUrlResponse, resourceUrlResponse.code)

          if (resourceUrlResponse?.code) {
            $redirect.to('/auth/social-login')

            // Apple doesn't return name and email after the first login, so let's store in a cookie and use if name is blank next time round
            if (resourceUrlResponse?.given_name && resourceUrlResponse?.family_name) {
              $log.debug('Setting oauth profile to cookie', resourceUrlResponse.email)

              store.commit('auth/setLastOauthProfile', {
                email: resourceUrlResponse?.email,
                firstName: resourceUrlResponse?.given_name,
                lastName: resourceUrlResponse?.family_name
              })
            } else if (store.state.auth?.lastOauthProfile?.lastName) {
              const oauthProfile = store.state.auth.lastOauthProfile

              $log.debug('Getting oauth profile from offline store', oauthProfile)

              resourceUrlResponse.given_name = oauthProfile.firstName
              resourceUrlResponse.family_name = oauthProfile.lastName
              resourceUrlResponse.email = oauthProfile.email
            }

            store.dispatch('auth/handleSocialCallback', {
              provider: 'apple',
              accessToken: resourceUrlResponse.code,
              returnUrl,
              meta: {
                platform: 'ios',
                clientProfile: {
                  firstName: resourceUrlResponse?.given_name,
                  lastName: resourceUrlResponse?.family_name,
                  email: resourceUrlResponse?.email
                }
              }
            })
          } else {
            $log.debug('Apple login error, missing auth code in callback')
            $notify.error('Failed to login, please try again')
          }
        } catch (error) {
          $log.debug('Apple login error', error)
          $notify.error('Failed to login, please try again')
        }
      } else {
        // Specifically for Apple Web, we must do this manually and not use @byteowls/capacitor-oauth2

        // Setup listeners
        appleIDSignOnSuccessListener = event => {
          // Handle success

          const data = event.detail?.authorization

          if (data?.code) {
            $redirect.to('/auth/social-login')

            store.dispatch('auth/handleSocialCallback', {
              provider: 'apple',
              accessToken: data.code,
              returnUrl: process.env.APPLE_REDIRECT_URL,
              meta: {
                platform: 'web',
                clientProfile: {
                  firstName: data?.given_name,
                  lastName: data?.family_name,
                  email: data?.email
                }
              }
            })
          } else {
            $log.debug('Apple login error, missing auth code in callback')
            $notify.error('Failed to login, please try again')
          }
        }

        document.addEventListener('AppleIDSignInOnSuccess', appleIDSignOnSuccessListener)

        appleIDSignInOnFailureListener = event => {
          // Handle error
          $log.debug('Apple login error appleIDSignInOnFailureListener', event)
          $notify.error('Failed to login, please try again')
        }

        document.addEventListener('AppleIDSignInOnFailure', appleIDSignInOnFailureListener)
        //

        // Init Apple
        window.AppleID.auth.init({
          clientId: process.env.APPLE_SIGNIN_ID,
          scope: 'name email',
          redirectURI: process.env.APPLE_REDIRECT_URL,
          usePopup: true
        })

        // Call Sign In
        await window.AppleID.auth.signIn()
      }
    }
  }

  // Inject to context as $socialLogin
  inject('socialLogin', socialLogin)
}
