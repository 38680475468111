import { render, staticRenderFns } from "./error.vue?vue&type=template&id=08a565ee"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/home/runner/work/platform/platform/packages/client/src/components/elements/typography/heading/Heading.vue').default,Button: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/button/Button.vue').default,Link: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/link/Link.vue').default,Brochureware: require('/home/runner/work/platform/platform/packages/client/src/components/layouts/brochureware/Brochureware.vue').default})
