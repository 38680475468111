import { PlatformApi } from '~/services/api/platform'

export class PaddockService extends PlatformApi {
  async getAll() {
    return await this.get(`/paddock`)
  }

  async getById(id) {
    return await this.get(`/paddock/${id}`)
  }

  async create() {
    return await this.post(`/paddock`)
  }

  async update(id) {
    return await this.put(`/paddock/${id}`)
  }

  async saveMaintenanceSchedule(id) {
    return await this.post(`/paddock/${id}/maintenance`)
  }

  async saveOpeningHours(id) {
    return await this.post(`/paddock/${id}/hours`)
  }

  async getAllOccupancy({ startAt, endAt }) {
    return await this.get(`/paddock/occupancy/all`, { startAt, endAt })
  }

  async getOccupancy({ paddockId, startAt, endAt }) {
    return await this.get(`/paddock/occupancy`, { paddockId, startAt, endAt })
  }

  async checkForExistingMaintenanceBookings({ paddockId, dayOfWeek, startTime, endTime }) {
    return await this.post(`/paddock/maintenance/check`, { paddockId, dayOfWeek, startTime, endTime })
  }
}
