import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=370d04d8"
import script from "./SocialLinks.vue?vue&type=script&lang=js"
export * from "./SocialLinks.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/link/Link.vue').default})
