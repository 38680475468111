import { PlatformApi } from '~/services/api/platform'

export class UserService extends PlatformApi {
  async getAllCustomers() {
    return await this.get(`/user/customers`)
  }

  async getById(userId) {
    return await this.get(`/user/${userId}`)
  }

  async getLastActiveAt(userId) {
    return await this.get(`/user/${userId}/last-active`)
  }

  async deleteById(userId) {
    return await this.delete(`/user/${userId}`)
  }

  async restoreById(userId) {
    return await this.put(`/user/${userId}/restore`)
  }

  async impersonate(userId) {
    return await this.post(`/user/${userId}/impersonate`)
  }

  async saveProfile(userId) {
    const model = { ...this.state.model }

    if (!model.avatar || !model.avatar?.fileId) {
      delete model.avatar
    }

    // Admin editing user
    if (userId) {
      return await this.put(`/user/${userId}`, model)
    } else {
      return await this.put(`/user/profile`, model)
    }
  }

  async deleteProfile() {
    return await this.delete(`/user/account`)
  }

  async mergeAccounts(form) {
    return await this.post(`/user/merge`, form)
  }

  async adminCreate() {
    return await this.post(`/user`)
  }

  async getAdminMap() {
    return await this.get(`/user/admin-map`)
  }
}
