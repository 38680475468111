import { camelCase, capitalize, cloneDeep, get, set, upperFirst } from 'lodash'
import Vue from 'vue'

import { ApiModel } from '~/plugins/api/model'
import { isNative, operatingSystem, platform } from '~/plugins/native/capacitor'

const initialState = (existingState = {}) => {
  const state = {
    isClientLoaded: false,
    hasFetchedInitialStatus: false,
    hasFailedToFetchInitialStatus: false,
    statusApi: new ApiModel(),
    globalBannerApi: new ApiModel(),
    globalBannerLastFetchedAt: null,
    maintenanceMode: {
      enabled: false,
      bypassed: false,
      message: null,
      resolvedByDateUTC: null
    },
    globalMessage: null
  }

  return state
}

export const state = () => cloneDeep(initialState())

export const getters = {
  isMaintenanceModeEnabled: state => state.maintenanceMode.enabled,
  isMaintenanceModeBypassed: state => state.maintenanceMode.bypassed,

  statusFailedToLoad: state => state.statusApi.response?.code === 403,

  globalBanner: state => state.globalBannerApi?.response?.data || null,

  getApp: (state, getters, rootState, rootGetters) => {
    const app = {}

    // GENERAL APP CONFIG

    app.config = state.config
    app.platform = platform
    app.operatingSystem = operatingSystem
    app.deviceDetails = rootState.device.details[isNative ? 'native' : 'web']
    app.version = rootState.device.bundleVersion
    app.nativeVersion = get(app.deviceDetails, 'app.version', null)
    app.user = rootGetters['auth/getUser']
    app.currentOrganisation = rootGetters['auth/getCurrentOrganisation']
    app.role = get(app.currentOrganisation, 'role', null)
    app.organisations = rootGetters['auth/userOrganisations']
    app.socialAuthProvider = app.user.socialAuth?.length > 0 ? app.user.socialAuth[0].provider : null

    // BOOLEAN STATES

    // app.isProduction app.isLocal etc
    app[`is${capitalize(process.env.APP_ENVIRONMENT)}`] = true
    app[`is${capitalize(platform)}`] = true
    app[`is${capitalize(operatingSystem)}`] = true
    app[`is${upperFirst(camelCase(app.role))}`] = true
    app.isAuth = rootGetters['auth/hasToken'] && rootState.auth.user?.id
    app.isFirstLoad = rootState.navigation.routeHistory.length < 2
    app.isNative = isNative
    app.isMobileOrNative = isNative || Vue.prototype.$screen.width <= 1024
    app.isUsingTouch = process.client && window.matchMedia('(any-pointer: coarse)').matches
    app.isMaintenanceModeEnabled = getters.isMaintenanceModeEnabled && !getters.isMaintenanceModeBypassed
    app.isMaintenanceModeEnabledForOthers = getters.isMaintenanceModeEnabled
    app.isClientLoaded = state.isClientLoaded
    app.isReady = state.hasFetchedInitialStatus
    app.hasFailedToLoad = (getters.statusFailedToLoad || state.hasFailedToFetchInitialStatus) && !app.isReady
    app.isWaitingForPushNotificationSetup = rootGetters['notifications/isWaitingForPushNotificationSetup']
    app.isDebug = process.client ? window.location.search.includes('dev=true') : false
    app.isOnline = rootGetters['device/isOnline']
    app.isStaff = app.currentOrganisation?.isStaff === true
    app.isImpersonatingUser = rootState.auth.isImpersonatingUser
    app.isMultiOrganisationUser = app.organisations.length > 1
    app.isUsingSocialAuth = app.user.socialAuth?.length > 0
    app.hasFilesUploading = rootGetters['files/hasFilesUploading']
    app.isStaffPortal = rootGetters['navigation/currentRoutePath'].startsWith('/admin')
    app.isBrochureware =
      !rootGetters['navigation/currentRoutePath'].startsWith('/platform') &&
      !rootGetters['navigation/currentRoutePath'].startsWith('/admin')

    if (process.client) {
      app.isRetina = window.devicePixelRatio >= 2
    }

    return app
  }
}

export const actions = {
  // This is our entry API call. Here we find out if we are authed or not, and what the config for the app/org is
  async fetchStatus({ state, rootState, commit, dispatch, getters }) {
    // If we are offline and booting up again let's apply permissions from the persisted state
    // as we won't be able to fetch them from the server
    if (!getters.getApp.isOnline && rootState.auth.permissions?.length > 0) {
      this.$ability.update(rootState.auth.permissions)

      return
    }

    try {
      await this.$api.system(state.statusApi).useStorePath('app.statusApi').getStatus()

      const user = get(state.statusApi, 'response.data.user', {})

      if (!user.address?.addressLine1 && user.addressLine1) {
        user.address = {
          addressLine1: user.addressLine1,
          addressLine2: user.addressLine2,
          addressLocality: user.addressLocality,
          addressRegion: user.addressRegion,
          addressCode: user.addressCode,
          addressCountry: user.addressCountry,
          addressCoordinates: user.addressCoordinates,
          addressCoordinatesLat: user.addressCoordinatesLat,
          addressCoordinatesLng: user.addressCoordinatesLng
        }
      }

      commit('auth/setUser', user, { root: true })

      if (state.statusApi.response?.data?.permissions) {
        commit('auth/setPermissions', state.statusApi.response.data.permissions, { root: true })

        this.$ability.update(state.statusApi.response.data.permissions)
      }

      dispatch('auth/setupActiveOrganisation', null, { root: true })

      commit('setState', { key: 'hasFetchedInitialStatus', value: true })
      commit('setState', { key: 'hasFailedToFetchInitialStatus', value: false })

      dispatch('auth/handleUserResponse', {}, { root: true })
    } catch (error) {
      // Times out after around 30 seconds
      this.$log.error('Error fetching /status', error)
      commit('setState', { key: 'hasFailedToFetchInitialStatus', value: true })
    }
  },

  async fetchGlobalBanner({ commit, state }) {
    // If 5 minutes hasn't passed since the last fetch, don't fetch again
    if (state.globalBannerLastFetchedAt && new Date().getTime() - state.globalBannerLastFetchedAt < 300000) {
      return
    }

    try {
      await this.$api.announcement(state.globalBannerApi).useStorePath('app.globalBannerApi').getGlobalBanner()

      commit('setBannerLastFetchedAt')
    } catch (error) {
      this.$log.error('Error fetching global banner', error)
    }
  }
}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  setMaintenanceMode(state, maintenanceModeConfig) {
    set(state, 'maintenanceMode', maintenanceModeConfig)
  },

  setClientLoaded(state) {
    state.isClientLoaded = true
  },

  setBannerLastFetchedAt(state) {
    state.globalBannerLastFetchedAt = new Date().getTime()
  },

  reset(state) {
    this.$log.debug('Resetting app module')

    Object.assign(state, cloneDeep(initialState()))
  }
}
