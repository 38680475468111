import { PlatformApi } from '~/services/api/platform'

export class MaintenanceService extends PlatformApi {
  async getAll() {
    await this.get(`/maintenance/jobs`)
  }

  async getOpen() {
    await this.get(`/maintenance/jobs/open`)
  }

  async getClosed() {
    await this.get(`/maintenance/jobs/closed`)
  }

  async getRecentlyClosed() {
    await this.get(`/maintenance/jobs/closed/recent`)
  }

  async createJob() {
    await this.post(`/maintenance/jobs`)
  }

  async updateJob(jobId) {
    await this.put(`/maintenance/jobs/${jobId}`)
  }

  async deleteJob(jobId) {
    await this.delete(`/maintenance/jobs/${jobId}`)
  }
}
