let platform = 'web'
let operatingSystem = 'mac'
let isNative = false

const loadCapacitor = async function () {
  console.log('Core capacitor init')

  const { Device } = await import('@capacitor/device')

  const deviceInfo = await Device.getInfo()

  platform = deviceInfo.platform
  operatingSystem = deviceInfo.operatingSystem
  isNative = window.Capacitor.isNativePlatform()
}

export { isNative, loadCapacitor, operatingSystem, platform }
