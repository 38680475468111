
export default {
  name: 'ModalWrapper',

  data() {
    return {
      allowOverlayClose: true
    }
  },

  watch: {
    // When using browser history buttons let's see if we need to auto close any modals
    '$route.query.action'() {
      this.$modal.syncActionUrl()
    }
  },

  mounted() {
    if (this.$route.query.action) {
      // We convert slashes to tidle to make it more readable in the URL, so we need to convert it back
      const modals = this.$route.query.action.replace('~', '/').split(',')

      // If this is a fresh page load we only want the first in the chain as we won't have the
      // form data from previous chained modals to open the last one
      this.$modal.open(modals[0])
    }
  },

  methods: {
    closeActiveModal() {
      this.$modal.close(this.$store.getters['modal/activeModal'].modalName)
    }
  }
}
