
export default {
  name: 'Heading',

  props: {
    level: {
      type: [String, Number],
      default: 1,
      validator: prop => Number(prop) < 5
    },

    tagLevel: {
      type: [String, Number],
      default: null
    },

    titleIcon: {
      type: [Object, String],
      default: null
    }
  },

  computed: {
    compLevel() {
      return `h${this.tagLevel || this.level}`
    }
  }
}
