const initialState = () => {
  return {
    fileIdsUploading: []
  }
}

export const state = () => initialState()

export const getters = {
  hasFilesUploading(state, getters) {
    return state.fileIdsUploading.length > 0
  },

  isFileIdCurrentlyUploading: state => currentFileId => {
    return state.fileIdsUploading.includes(currentFileId)
  }
}

export const mutations = {
  addFileIdCurrentlyUploading(state, currentFileId) {
    state.fileIdsUploading.push(currentFileId)
  },

  removeFileIdsCurrentlyUploading(state, currentFileId) {
    state.fileIdsUploading = state.fileIdsUploading.filter(fileId => fileId !== currentFileId)
  },

  resetAllFilesCurrentlyUploading(state) {
    state.fileIdsUploading = []
  }
}
