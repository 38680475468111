import { cloneDeep, throttle } from 'lodash'
import safeJsonStringify from 'safe-json-stringify'

import { ApiModel } from '~/plugins/api/model'

// import { keyedDebounce } from '~/lib/utility/keyed-debounce'

const blackListedModules = ['navigation', 'modal', 'device', 'overTheAirUpdates', 'api-data-cache', 'i18n']

export const storePersist = {
  hasCompletedInitialLoad: false,
  store: null,

  setup: store => {
    storePersist.store = store

    // Watch is triggered when any state changes and sends the entire state
    // We do this as a catchall every 10 seconds
    store.watch(state => state, storePersist.saveAllThrottled, {
      deep: true
    })

    // Subscribe occurs every mutation and let's us know which module was mutated
    // This allows us to only save the state of the module that was mutated to save hammering the filesystem
    // store.subscribe((mutation, state) => {
    //   // Global mutations won't have a forward slash in the mutation path, but their payload will tell us the module name
    //   const moduleNameSplit =
    //     mutation.type === 'setGlobalState' ? mutation.payload.statePath.split('.') : mutation.type.split('/')
    //   const moduleName = moduleNameSplit[0]

    //   if (moduleName) {
    //     storePersist.saveModuleThrottled(moduleName, state[moduleName])
    //   }
    // })
  },

  // Let's load all our our state based on the module names, they may not exist especially on first load
  load: async store => {
    const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

    const modules = Object.keys(store._modules.root._children).filter(
      moduleName => !blackListedModules.includes(moduleName)
    )

    // We can't use $log.debug in this file because plugins aren't loaded when this is called (it's called by route middleware on app boot)
    console.log('Loading offline state')

    try {
      await Promise.all(
        modules.map(async moduleName => {
          try {
            const state = await Filesystem.readFile({
              path: `${moduleName}.json`,
              directory: Directory.Data,
              encoding: Encoding.UTF8
            })

            if (state) {
              store.commit('setGlobalState', { statePath: moduleName, newState: JSON.parse(state.data) })
              console.log('Loaded offline state', `${moduleName}.json`)
            } else {
              throw new Error('No state found in file')
            }
          } catch (error) {
            console.log('No offline state found', moduleName)
          }
        })
      )
    } catch (error) {
      console.log('Error reading offline state', error)
    } finally {
      console.log('Finished loading all offline state')
      storePersist.hasCompletedInitialLoad = true
    }
  },

  // We are passed all of our state, let's save each module in it's own file
  saveAllThrottled: throttle(async state => {
    await storePersist.saveAll(state)
  }, 15000),

  saveAll: async state => {
    // Don't save anything until we've completed our first load. Loading triggers saves!
    if (!storePersist.hasCompletedInitialLoad) {
      return false
    }

    const { App } = await import('@capacitor/app')

    const appState = await App.getState()

    if (appState?.isActive) {
      const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

      const modules = Object.keys(state).filter(moduleName => !blackListedModules.includes(moduleName))

      try {
        await Promise.all(
          modules.map(async moduleName => {
            await Filesystem.writeFile({
              path: `${moduleName}.json`,
              data: safeJsonStringify(storePersist.preProcessModuleState(moduleName, state[moduleName])),
              directory: Directory.Data,
              encoding: Encoding.UTF8
            })
          })
        )
      } catch (error) {
        console.log(`Error saving offline state`, error)
      }
    }
  },

  saveModuleThrottled: (moduleName, moduleState) => {
    // Don't save anything until we've completed our first load. Loading triggers saves!
    if (!storePersist.hasCompletedInitialLoad || blackListedModules.includes(moduleName)) {
      return false
    }

    // TODO: Fix keyed-debounce
    storePersist.saveModule(moduleName, moduleState)
  },

  saveModule: async (moduleName, moduleState) => {
    // We don't want to keep hammering the file system API for log syncing if we are online
    if (moduleName === 'logs' && storePersist.store.state.device.network.isOnline) {
      return false
    }

    const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

    try {
      await Filesystem.writeFile({
        path: `${moduleName}.json`,
        data: safeJsonStringify(storePersist.preProcessModuleState(moduleName, moduleState)),
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
    } catch (error) {
      console.log(`Error saving offline state: ${moduleName}`, error)
    }
  },

  // Make any modifications to the state before saving that will be needed to prevent strange behaviour when restored
  preProcessModuleState: (moduleName, moduleState) => {
    const state = cloneDeep(moduleState)

    if (moduleName === 'auth') {
      state.socialProviderRedirectingTo = null
    }

    if (moduleName === 'app') {
      state.isClientLoaded = false
      state.statusApi = new ApiModel()
    }

    if (moduleName === 'notifications') {
      state.pushNotifications.isLoading = false
      state.pushNotifications.token = null
    }

    if (moduleName === 'booking') {
      state.isPurchasing = false
      state.discountCode = null
      state.creditBalance = 0
    }

    return state
  },

  deleteAllLocalStateFiles: async () => {
    const { Filesystem, Directory } = require('@capacitor/filesystem')

    const localFiles = await Filesystem.readdir({ directory: Directory.Data, path: '.' })

    const localStateFiles = localFiles.files.filter(file => {
      return file && file.name.includes('.json')
    })

    await Promise.all(
      localStateFiles.map(async file => {
        await Filesystem.deleteFile({
          path: file.name,
          directory: Directory.Data
        })
      })
    )

    // window.location.reload()
  }
}
