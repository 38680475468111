import { get } from 'lodash'
import { set } from 'vue'

import { ApiModel } from '~/plugins/api/model'

const initialState = () => {
  return {
    list: [],
    lastFetchedAllPaddocksMs: 0,
    fetchPaddocksApi: new ApiModel(),
    createPaddockApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  all: state => {
    return state.list.filter(paddock => paddock.isActive && paddock?.openingHours)
  },

  dogWashPaddocks: state => {
    return state.list.filter(paddock => paddock.isActive && paddock?.openingHours && paddock.hasDogWash)
  },

  paddockOptionsAll: (state, getters) => {
    return getters.all.map(paddock => ({
      value: paddock.id,
      label: paddock.name
    }))
  },

  paddockOptions: (state, getters, rootState, rootGetters) => {
    const paddockOptions = []

    rootGetters['booking/previousPaddocks'].forEach(paddock => {
      paddockOptions.push({
        value: paddock.id,
        label: paddock.name,
        group: 'Recently booked'
      })
    })

    return paddockOptions.concat(
      getters.all
        .map(paddock => ({
          value: paddock.id,
          label: `${paddock.name} - 5 miles away`,
          group: 'Closest paddocks'
        }))
        .reverse()
    )
  },

  paddockById: state => id => {
    return state.list.find(paddock => paddock.id === id)
  },

  paddockBySlug: state => slug => {
    return state.list.find(paddock => paddock.slug === slug)
  }
}

export const actions = {
  async fetchAll({ state, commit }) {
    try {
      if (
        state.lastFetchedAllPaddocksMs &&
        this.$dateNew.fromMillis(state.lastFetchedAllPaddocksMs).diffNow('seconds').seconds > -30
      ) {
        // console.log('Not fetching paddocks as last fetch was less than 30 seconds ago')
        return false
      }

      commit('setLastFetchedMs', this.$dateNew.now().toMillis())

      await this.$api.paddock(state.fetchPaddocksApi).useStorePath('paddocks.fetchPaddocksApi').getAll()

      commit('setPaddocks', get(state.fetchPaddocksApi, 'response.data', []))
    } catch (error) {
      this.$log.error('Error fetching paddocks', error)
    }
  }
}

export const mutations = {
  setLastFetchedMs(state, lastFetchedAllPaddocksMs) {
    set(state, 'lastFetchedAllPaddocksMs', lastFetchedAllPaddocksMs)
  },

  setPaddocks(state, paddocks) {
    set(state, 'list', paddocks)
  },

  reset(state) {
    this.$log.debug('Resetting paddocks module')

    Object.assign(state, initialState())
  }
}
