import jwtDecode from 'jwt-decode'
import { cloneDeep, get, set } from 'lodash'
import Vue from 'vue'

import AuthEntity from '@/lib/entities/auth'
import ForgottenPasswordEntity from '@/lib/entities/forgottenPassword'
import ResetPasswordEntity from '@/lib/entities/resetPassword'
import { ApiModel } from '~/plugins/api/model'
import { isNative } from '~/plugins/native/capacitor'

export const STORAGE_KEY_ACCESS_TOKEN = 'access-token'
export const STORAGE_KEY_ACCESS_TOKEN_EXPIRES = 'access-token-expires'
export const STORAGE_KEY_REFRESH_TOKEN = 'refresh-token'
export const STORAGE_KEY_ORGANISATION = 'organisation'
export const STORAGE_KEY_LAST_SOCIAL_PROVIDER = 'last-social-provider'

const initialState = () => {
  return {
    user: {},
    permissions: [],
    activeOrganisationId: null,
    accessToken: null,
    accessTokenExpiresAt: null,
    refreshToken: null,
    isImpersonatingUser: false,
    impersonatingOriginalAdmin: null,
    socialProviderRedirectingTo: null,
    lastSocialProvider: null,
    hasBiometrics: false,
    lastOauthProfile: {
      email: null,
      firstName: null,
      lastName: null
    },
    loginApi: new ApiModel(new AuthEntity().model),
    forgottenPasswordApi: new ApiModel(new ForgottenPasswordEntity().model),
    resetPasswordApi: new ApiModel(new ResetPasswordEntity().model),
    refreshTokenApi: new ApiModel(),
    socialLoginApi: new ApiModel()
  }
}

export const state = () => cloneDeep(initialState())

export const getters = {
  hasToken: state => {
    return state.accessToken !== null
  },

  getUser: state => {
    return state.user || {}
  },

  userOrganisations: state => {
    return get(state.user, 'organisations', [])
  },

  getCurrentOrganisation: (state, getters) => {
    return getters.userOrganisations.find(organisation => organisation.id === state.activeOrganisationId)
  },

  organisationSwitchingOptions: (state, getters) => {
    return getters.userOrganisations.map(organisation => ({
      label: `Switch to ${organisation.isStaff ? 'Admin portal' : organisation.name}`,
      value: organisation.id
    }))
  },

  hasTriggeredForgotPassword: state => {
    return [200, 404].includes(state.forgottenPasswordApi.response.code)
  }
}

export const actions = {
  async loadExistingTokens({ dispatch, commit, state }) {
    let refreshToken = state.refreshToken || this.$cookies.get(STORAGE_KEY_REFRESH_TOKEN)

    if (isNative && !refreshToken) {
      const getRefreshToken = await this.$native.secureStorage.get({ key: 'refreshToken' }).catch(() => {})

      if (getRefreshToken?.value) {
        refreshToken = getRefreshToken.value
        console.log('not found loading keychain version', refreshToken)
      }
    }

    if (refreshToken) {
      commit('setRefreshToken', refreshToken)
    }

    let accessToken = state.accessToken || this.$cookies.get(STORAGE_KEY_ACCESS_TOKEN)

    if (isNative && !accessToken) {
      const getAccessToken = await this.$native.secureStorage.get({ key: 'accessToken' }).catch(() => {})

      if (getAccessToken?.value) {
        accessToken = getAccessToken.value
        console.log('not found loading keychain version', accessToken)
      }
    }

    if (accessToken) {
      await dispatch('handleAccessToken', accessToken)
    }

    if (this.$cookies.get(STORAGE_KEY_LAST_SOCIAL_PROVIDER)) {
      commit('setLastSocialProvider', this.$cookies.get(STORAGE_KEY_LAST_SOCIAL_PROVIDER))
    }
  },

  loadExistingOrganisationId({ dispatch }) {
    if (this.$cookies.get(STORAGE_KEY_ORGANISATION)) {
      dispatch('changeOrganisationId', this.$cookies.get(STORAGE_KEY_ORGANISATION))
    }
  },

  async changeOrganisationId({ commit, dispatch }, organisationId) {
    commit('setActiveOrganisationId', organisationId)

    await dispatch('app/fetchStatus', null, { root: true })
  },

  setupActiveOrganisation({ commit, state, dispatch }) {
    // Don't recalculate if we already have an active organisation
    if (state.activeOrganisationId) {
      return false
    }

    const userOrganisations = get(state, 'user.organisations', [])

    if (userOrganisations.length === 0) {
      return false
    }

    if (userOrganisations.length > 0) {
      this.$analytics.setUserProperty('organisation', userOrganisations[0].name)
      return dispatch('changeOrganisationId', userOrganisations[0].id)
    }

    const previousOrganisationId = this.$cookies.get(STORAGE_KEY_ORGANISATION)
    const existingOrganisation = userOrganisations.find(organisation => organisation.id === previousOrganisationId)

    if (previousOrganisationId && existingOrganisation) {
      this.$analytics.setUserProperty('organisation', existingOrganisation.name)
      return dispatch('changeOrganisationId', previousOrganisationId)
    }
  },

  async login({ commit, state, dispatch, rootGetters }, formModel) {
    await this.$api.auth(state.loginApi).useStorePath('auth.loginApi').login(formModel)

    if (state.loginApi.response?.data?.accessToken) {
      dispatch('handleTokens', {
        accessToken: state.loginApi.response.data.accessToken,
        refreshToken: state.loginApi.response.data.refreshToken
      })
    }

    await dispatch('app/fetchStatus', null, { root: true })

    this.$analytics.addEvent('Auth: Logged in')

    dispatch('handleUserResponse')

    commit('setLastSocialProvider', null)

    dispatch('app/fetchGlobalBanner', null, { root: true })
  },

  async handleUserResponse(
    { rootGetters, dispatch },
    { accessToken = null, refreshToken = null, shouldFetchStatus = false } = {}
  ) {
    if (accessToken && refreshToken) {
      dispatch('handleTokens', {
        accessToken,
        refreshToken
      })
    }

    if (shouldFetchStatus) {
      await dispatch('app/fetchStatus', null, { root: true })
    }

    const app = rootGetters['app/getApp']

    if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
      this.$sentry.configureScope(scope => {
        scope.setUser({
          userId: app.user?.id,
          organisation: app.currentOrganisation?.name,
          email: app.user?.email,
          mobileNumber: app.user?.mobileNumber,
          name: app.user?.fullName
        })
      })
    }

    if (process.env.APP_ANALYTICS_ENABLED === 'true') {
      this.$analytics.setUserProperty('role', app.role)
      this.$analytics.setUserProperty('email', app.user?.email)
      this.$analytics.setUserId(app.user?.id)

      this.$sessionRecording.identify(app.user?.id, {
        organisationId: app.currentOrganisation?.id,
        email: app.user?.email,
        isStaff: app.isStaff
      })
    }

    if (isNative) {
      this.$otaUpdates.setUserId(app.user?.id)

      this.$pushNotifications.checkPermissions()
    }
  },

  loginSocial({ commit }, { provider, returnTo }) {
    commit('setSocialProviderRedirectingTo', provider)

    commit('setLastSocialProvider', provider)

    setTimeout(() => {
      commit('setSocialProviderRedirectingTo', null)
    }, 2000)

    if (!returnTo) {
      returnTo = window.location.href
    }

    this.$analytics.addEvent(`Auth: Redirecting to social login ${provider}`, returnTo)

    this.$socialLogin[provider](returnTo)
  },

  async handleSocialCallback({ commit, dispatch, state, rootGetters }, { provider, accessToken, meta, returnTo }) {
    this.$log.debug(`Handling social callback for ${provider}`, returnTo)

    await this.$api
      .auth(state.socialLoginApi)
      .useStorePath('auth.socialLoginApi')
      .loginSocial(provider, accessToken, meta)

    if (state.socialLoginApi.response?.data?.accessToken) {
      dispatch('handleTokens', {
        accessToken: state.socialLoginApi.response.data.accessToken,
        refreshToken: state.socialLoginApi.response.data.refreshToken
      })
    }

    await dispatch('app/fetchStatus', null, { root: true })

    this.$analytics.addEvent('Auth: Logged in social', provider)

    dispatch('handleUserResponse')

    if (returnTo) {
      this.$redirect.to(returnTo)
    } else if (rootGetters['booking/totalItemsInBasket'] > 0 && !isNative) {
      this.$redirect.to('/book/checkout')
    } else {
      this.$redirect.to('/platform')
    }
  },

  async refreshToken({ commit, state, dispatch }) {
    try {
      let refreshToken = state.refreshToken || this.$cookies.get(STORAGE_KEY_REFRESH_TOKEN)

      if (isNative && !refreshToken) {
        const getRefreshToken = await this.$native.secureStorage.get({ key: 'refreshToken' }).catch(() => {})

        if (getRefreshToken?.value) {
          refreshToken = getRefreshToken.value
        }
      }

      if (!refreshToken) {
        throw new Error('No refresh token found')
      }

      this.$log.debug('Refreshing token')

      await this.$api.auth(state.refreshTokenApi).useStorePath('auth.refreshTokenApi').refreshToken(refreshToken)

      if (get(state.refreshTokenApi, 'response.data.accessToken')) {
        this.$log.debug('Token refreshed successfully')

        dispatch('handleTokens', {
          accessToken: state.refreshTokenApi.response.data.accessToken,
          refreshToken: state.refreshTokenApi.response.data.refreshToken
        })

        return state.refreshTokenApi.response.data.accessToken
      } else {
        throw new Error('Token refresh failed')
      }
    } catch (error) {
      this.$log.debug('Auth: Refresh token failed', error)

      dispatch('handleAuthFailure')

      return false
    }
  },

  async handleAuthFailure({ state, commit, dispatch }, shouldFetchStatus = true) {
    commit('reset')
    commit('app/reset', null, { root: true })
    dispatch('modal/closeAll', null, { root: true })

    if (shouldFetchStatus) {
      // Try to load sys again but without a token this time
      await dispatch('app/fetchStatus', null, { root: true })
    }
  },

  async logout({ state, dispatch }) {
    await this.$modal.closeAll()

    try {
      this.$analytics.addEvent('Auth: Logged out')

      await dispatch('resetAllModules', null, { root: true })

      if (state.hasBiometrics) {
        await this.$biometrics.deleteCredentials()
      }

      this.$analytics.reset()

      await dispatch('app/fetchStatus', null, { root: true })

      dispatch('app/fetchGlobalBanner', null, { root: true })
    } catch (error) {
      this.$log.error('Error signing out', error)
    } finally {
      this.$redirect.to('/auth/login')
    }
  },

  async requestForgottenPassword({ state, commit, dispatch }, formModel) {
    await this.$api
      .auth(state.forgottenPasswordApi)
      .useStorePath('auth.forgottenPasswordApi')
      .forgotPassword(formModel)

    this.$analytics.addEvent('Auth: Forgotten password')
  },

  async resetPassword({ state, commit, dispatch }, formModel) {
    await this.$api.auth(state.resetPasswordApi).useStorePath('auth.resetPasswordApi').resetPassword(formModel)

    if (get(state.resetPasswordApi, 'response.data.accessToken')) {
      dispatch('handleTokens', {
        accessToken: state.resetPasswordApi.response.data.accessToken,
        refreshToken: state.resetPasswordApi.response.data.refreshToken
      })
    }

    await dispatch('app/fetchStatus', null, { root: true })

    this.$analytics.addEvent('Auth: Reset password')
  },

  async handleTokens({ state, commit, dispatch }, { accessToken, refreshToken }) {
    commit('setRefreshToken', refreshToken)
    await dispatch('handleAccessToken', accessToken)
  },

  async handleAccessToken({ commit, dispatch }, token) {
    const tokenData = jwtDecode(token)

    const expiresAt = this.$dateNew.fromSeconds(tokenData.exp)
    const minutesTillExpires = expiresAt.diff(this.$dateNew.now(), 'minutes').minutes

    console.log({ minutesTillExpires })

    if (minutesTillExpires < 5) {
      this.$log.debug('Auth: Previous token has expired')

      try {
        await dispatch('refreshToken')
      } catch (error) {
        // Stub
      }
    } else {
      commit('setAccessToken', { token, expiresAt: expiresAt.toISO() })

      if (tokenData.impId) {
        commit('setState', { key: 'isImpersonatingUser', value: true })
        commit('setState', {
          key: 'impersonatingOriginalAdmin',
          value: {
            id: tokenData.impId,
            fullName: tokenData.impName
          }
        })
      }
    }
  }
}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  setAccessToken(state, { token, expiresAt }) {
    state.accessToken = token
    state.accessTokenExpiresAt = expiresAt

    this.$cookies.set(STORAGE_KEY_ACCESS_TOKEN, token, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })

    if (isNative) {
      this.$native.secureStorage.set({ key: 'accessToken', value: token })
    }
  },

  setRefreshToken(state, token) {
    state.refreshToken = token

    this.$cookies.set(STORAGE_KEY_REFRESH_TOKEN, token, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })

    if (isNative) {
      this.$native.secureStorage.set({ key: 'refreshToken', value: token })
    }
  },

  setActiveOrganisationId(state, organisationId) {
    state.activeOrganisationId = organisationId

    this.$cookies.set(STORAGE_KEY_ORGANISATION, organisationId, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })
  },

  setSocialProviderRedirectingTo(state, provider) {
    state.socialProviderRedirectingTo = provider
  },

  setLastSocialProvider(state, provider) {
    state.lastSocialProvider = provider

    this.$cookies.set(STORAGE_KEY_LAST_SOCIAL_PROVIDER, provider, {
      expires: this.$date().add(365, 'day').toDate(),
      path: '/'
    })
  },

  setUser(state, user) {
    state.user = user
  },

  setHasBiometrics(state, hasBiometrics) {
    state.hasBiometrics = hasBiometrics
  },

  setLastOauthProfile(state, profile) {
    state.lastOauthProfile = profile
  },

  setPermissions(state, permissions) {
    Vue.set(state, 'permissions', permissions)
  },

  async reset(state) {
    this.$log.debug('Resetting auth module')

    this.$cookies.remove(STORAGE_KEY_ACCESS_TOKEN)
    this.$cookies.remove(STORAGE_KEY_ACCESS_TOKEN_EXPIRES)
    this.$cookies.remove(STORAGE_KEY_REFRESH_TOKEN)
    this.$cookies.remove(STORAGE_KEY_ORGANISATION)

    if (isNative) {
      try {
        await this.$native.secureStorage.get({ key: 'accessToken' })

        this.$native.secureStorage.remove({ key: 'accessToken' })
      } catch (error) {
        // STUB
      }

      try {
        await this.$native.secureStorage.get({ key: 'refreshToken' })

        this.$native.secureStorage.remove({ key: 'refreshToken' })
      } catch (error) {
        // STUB
      }
    }

    // Clear any interval callbacks not caught by component destroys
    if (this.$activityRefresh) {
      this.$activityRefresh.clear()
    }

    const hasBiometrics = state.hasBiometrics

    Object.assign(state, cloneDeep(initialState()))

    state.hasBiometrics = hasBiometrics
  }
}
