// Stop Nuxt redirecting uncaught errors to the error page if local dev environment.
// This allows us to use Vue devtools to debug components at the time of the error.
export default function ({ app, $log }) {
  // if (process.client && process.env.APP_ENVIRONMENT === 'local') {
  //   app.nuxt.error = error => {
  //     console.error('Unhandled exception', error)
  //     $log.error('Unhandled exception', error)
  //   }
  // }
}
