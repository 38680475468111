
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'Banner',

  props: {
    type: {
      type: String,
      default: 'warning',
      validator: prop => ['information', 'warning', 'error', 'success'].includes(prop)
    },

    showIcon: {
      type: Boolean,
      default: true
    },

    icon: {
      type: Object,
      default: null
    }
  },

  computed: {
    compIcon() {
      if (this.icon) {
        return this.icon
      }

      let icon = faInfoCircle

      switch (this.type) {
        case 'warning':
          icon = faExclamationTriangle
          break

        case 'error':
          icon = faTimesCircle
          break

        case 'success':
          icon = faCheckCircle
          break
      }

      return icon
    }
  }
}
