import { PlatformApi } from '~/services/api/platform'

export class SystemService extends PlatformApi {
  async getStatus() {
    return await this.get(`/system/status`)
  }

  async saveLogs(logs) {
    return await this.post(`/system/logs`, logs)
  }

  async sendContactMessage() {
    return await this.post('/system/contact')
  }

  async sendLandOwnerMessage() {
    return await this.post('/system/contact/land-owner')
  }

  async pageNotFound(fullPath) {
    return await this.post('/system/page-not-found', { fullPath })
  }

  async sendPushTest() {
    return await this.post('/system/admin/push-test')
  }

  async saveMaintenanceMode() {
    return await this.post('/system/admin/maintenance-mode')
  }
}
