// This plugin allows us to gloablly share the state of vuex app/getApp getter
// in a more convenient way. Note this is only available for Vue components, outside of that use:
// const app = this.$store.getters['app/getApp']'

import Vue from 'vue'

let uid = 0

const mixins = {
  // Add a unique id to each component
  beforeCreate() {
    this.uid = uid.toString()
    uid += 1
  },

  computed: {
    $app() {
      if (this.$store) {
        return this.$store.getters['app/getApp']
      } else {
        return {}
      }
    }
  }
}

Vue.mixin(mixins)
