import { set } from 'lodash'

const initialState = () => {
  return {
    currentLocale: null,
    locales: []
  }
}

export const state = () => initialState()

export const getters = {
  getCurrentLocale: state => {
    return state.currentLocale
  },

  getAvailableLocaleOptions: state => {
    return state.locales.map(locale => ({
      label: locale.name,
      value: locale.code
    }))
  }
}

export const actions = {}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  reset(state) {
    this.$log.debug('Resetting locale module')

    Object.assign(state, initialState())
  }
}
