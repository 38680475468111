import { strFromU8, strToU8, zlibSync } from 'fflate'
import { getRecordConsolePlugin, record } from 'rrweb'

import { platform } from '~/plugins/native/capacitor'

// To reduce bundle size and initial load time we load our session recording
// JS SDK after the page has loaded
export default ({ app, $log, $api, $sentry, $appVersion, $cookies }, inject) => {
  const SAVE_EVERY_SECONDS = 10
  const MAX_EVENTS_BEFORE_NEW_ID = 5000
  const MAX_BATCH_SIZE = 300

  const sessionRecording = {
    identity: {},
    customPageViewsPreQueue: [],
    customEventsPreQueue: [],
    customErrorsPreQueue: [],
    events: [],
    eventSentCount: 0,
    hasLoaded: false,
    id: null,

    generateId() {
      sessionRecording.id = Date.now().toString(36) + Math.random().toString(36).substring(2)

      $log.debug('Session ID', sessionRecording.id)
    },

    setup() {
      const uniqueScriptId = 'session-record'
      const existingScriptElement = document.getElementById(uniqueScriptId)

      // https://upload.wikimedia.org/wikipedia/commons/8/86/Europe_ISO_3166-1.svg
      const countryCode = $cookies.get('country-code')

      const shouldDisableSessionRecording = !!(
        (countryCode && !['GB', 'IM', 'IE', 'GG', 'JE'].includes(countryCode)) ||
        $cookies.get('is-bot')
      )

      // We need to prioritise logging to reduce storage costs
      if (existingScriptElement || shouldDisableSessionRecording || sessionRecording.identity?.isStaff) {
        $log.debug('Not eligable for session recording')
        return false
      }

      sessionRecording.generateId()

      $sentry.configureScope(scope => {
        scope.setExtra('sessionId', sessionRecording.id)
      })

      record({
        emit(event) {
          if (!sessionRecording.identity?.isStaff) {
            sessionRecording.events.push(event)
          }
        },
        plugins: [getRecordConsolePlugin()]
      })

      sessionRecording.hasLoaded = true

      if (sessionRecording.customPageViewsPreQueue.length > 0) {
        sessionRecording.customPageViewsPreQueue.forEach(pageEvent => {
          sessionRecording.addPageView(pageEvent)
        })

        sessionRecording.customPageViewsPreQueue = []
      }

      if (sessionRecording.customEventsPreQueue.length > 0) {
        sessionRecording.customEventsPreQueue.forEach(event => {
          sessionRecording.addEvent(event)
        })

        sessionRecording.customEventsPreQueue = []
      }

      if (sessionRecording.customErrorsPreQueue.length > 0) {
        sessionRecording.customErrorsPreQueue.forEach(event => {
          sessionRecording.addError(event)
        })

        sessionRecording.customErrorsPreQueue = []
      }

      setInterval(sessionRecording.saveToServer, SAVE_EVERY_SECONDS * 1000)

      // window.addEventListener('beforeunload', () => sessionRecording.saveToServer(true))

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          sessionRecording.saveToServer(true)
        }
      })
    },

    addPageView(pageEvent) {
      if (!sessionRecording.hasLoaded) {
        sessionRecording.customPageViewsPreQueue.push(pageEvent)
        return
      }

      record.addCustomEvent('page-view', pageEvent)
    },

    addEvent(eventData) {
      if (!sessionRecording.hasLoaded) {
        sessionRecording.customEventsPreQueue.push(eventData)
        return
      }

      record.addCustomEvent('event', eventData)
    },

    addError(errorData) {
      if (!sessionRecording.hasLoaded) {
        sessionRecording.customErrorsPreQueue.push(errorData)
        return
      }

      record.addCustomEvent('error', errorData)
    },

    async saveToServer(ignoreBatchSize = false) {
      if (sessionRecording.events.length === 0) {
        return
      }

      const maxBatchSize = ignoreBatchSize ? 99999999999999 : MAX_BATCH_SIZE

      // Process all logs in the queue and remove them
      const batch = [...sessionRecording.events]
      const limitedBatch = batch.slice(0, maxBatchSize)

      try {
        const eventsString = strFromU8(zlibSync(strToU8(JSON.stringify(limitedBatch))), true)

        const body = {
          eventsString,
          platform,
          version: $appVersion,
          locale: app.i18n.localeProperties.iso,
          guestId: $cookies.get('guest-id'),
          userId: sessionRecording.identity?.userId,
          organisationId: sessionRecording.identity?.organisationId,
          firstPageUrl: window.location.href,
          userAgent: window.navigator.userAgent,
          email: sessionRecording.identity?.email
        }

        sessionRecording.events = []

        await $api.sessionRecording().sendEvents(sessionRecording.id, body)

        sessionRecording.eventSentCount += limitedBatch.length

        if (sessionRecording.eventSentCount > MAX_EVENTS_BEFORE_NEW_ID) {
          sessionRecording.eventSentCount = 0
          sessionRecording.generateId()
        }
      } catch (error) {
        $log.debug('Error sending session recodings', error)

        // Re-add failed events back into the queue
        if (limitedBatch.length === 1) {
          sessionRecording.events.push(limitedBatch)
        } else {
          sessionRecording.events = [...limitedBatch, ...sessionRecording.events]
        }
      } finally {
        // If there were more events left in the batch, add them back to the queue
        if (batch.length > maxBatchSize) {
          sessionRecording.events = [...batch.slice(maxBatchSize), ...sessionRecording.events]
        }
      }
    },

    identify(userId, identity = {}) {
      this.identity = {
        userId,
        ...identity
      }
    }
  }

  // Inject to context as $sessionRecording
  inject('sessionRecording', sessionRecording)
}
