import { PlatformApi } from '~/services/api/platform'

export class OrganisationService extends PlatformApi {
  async register() {
    return await this.post(`/organisation/register`)
  }

  async getAll() {
    return await this.get(`/organisation`)
  }

  async getById(organisationId) {
    return await this.get(`/organisation/${organisationId}`)
  }

  async removeUser(userId) {
    return await this.delete(`/organisation/user/${userId}`)
  }

  async inviteUser() {
    return await this.post(`/organisation/invite`)
  }

  async updateUserRole(userId, role) {
    return await this.put(`/organisation/user/${userId}/role`, { role })
  }

  async getUsers() {
    return await this.get(`/organisation/user`)
  }
}
