import { PlatformApi } from '~/services/api/platform'

export class DiscountService extends PlatformApi {
  async create() {
    return await this.post(`/discount`)
  }

  async getById(id) {
    return await this.get(`/discount/${id}`)
  }

  async update(id) {
    return await this.put(`/discount/${id}`)
  }

  async deleteDiscount(id) {
    return await this.delete(`/discount/${id}`)
  }

  async checkCode() {
    return await this.get(`/discount/validate`)
  }

  async addToBasket() {
    return await this.post(`/discount/basket`)
  }

  async removeVoucherFromBasket(voucherId) {
    return await this.delete(`/discount/basket/${voucherId}`)
  }

  async generateImage(model) {
    return await this.post(`/discount/image`, model)
  }
}
