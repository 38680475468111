export default function () {
  return {
    model: {
      verificationCode: null,
      password: ['local'].includes(process.env.APP_ENVIRONMENT) ? 'testingthis' : null
    },
    validation: {
      password: { required: true, min: 8 }
    }
  }
}
