import { get } from 'lodash'

import ApiCore from '~/plugins/api/core'
import { platform } from '~/plugins/native/capacitor'

// This is the default API class for our own APIs, all our internal services will extend this
// If we need to call other third party APIs then copy/paste this class to make custom request/response changes
export class PlatformApi extends ApiCore {
  constructor(nuxtContext, apiModel, { timeout = 300000 } = {}) {
    super(nuxtContext, apiModel, null, timeout)

    // Modify the outgoing REQUEST
    this.axios.interceptors.request.use(async config => {
      config.baseURL = process.env.API_BASE_URL

      // If we need to refresh token before hand
      if (
        !config.url.includes('/auth/refresh') &&
        nuxtContext.store.state.auth.refreshToken &&
        nuxtContext.store.state.auth.accessToken &&
        nuxtContext.store.state.auth.accessTokenExpiresAt &&
        nuxtContext.$dateNew
          .fromISO(nuxtContext.store.state.auth.accessTokenExpiresAt)
          .diff(nuxtContext.$dateNew.now(), 'minutes').minutes < 5
      ) {
        nuxtContext.$log.debug('Refreshing expiring access token from PlatformApi')

        const newAccessToken = await nuxtContext.store.dispatch('auth/refreshToken')

        if (newAccessToken) {
          config.headers.Authorization = `Bearer ${newAccessToken}`
        }
      } else if (nuxtContext.store.state.auth.accessToken) {
        // Use existing access token
        config.headers.Authorization = `Bearer ${nuxtContext.store.state.auth.accessToken}`
      }

      if (nuxtContext.store.state.logs.guestId) {
        config.headers['x-guest-id'] = nuxtContext.store.state.logs.guestId
      }

      if (nuxtContext.store.state.auth.activeOrganisationId) {
        config.headers['x-organisation-id'] = nuxtContext.store.state.auth.activeOrganisationId
      }

      config.headers['x-platform'] = platform

      return config
    })

    // Handle RESPONSE
    this.axios.interceptors.response.use(
      // Successful response
      response => {
        const data = response.data.data

        // Fake pagination
        if (Array.isArray(data)) {
          response.data.pagination = {
            currentPage: 1,
            documentsPerPage: 1000,
            totalDocuments: data.length
          }
        }

        this.updateState()
        return response
      },
      // Error response
      error => {
        const statusCode = get(error, 'response.status') || 500

        this.state.response.message =
          statusCode === 500
            ? 'Something went wrong, please reload and try again'
            : get(error, 'response.data.errors[0].message') ||
              get(error, 'response.data.message') ||
              'Something went wrong, please reload and try again'

        this.updateState()

        // We need errors to bubble up to calling context promise
        error.shouldThrowFatal = true

        return Promise.reject(error)
      }
    )
  }
}
