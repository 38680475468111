
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

export default {
  name: 'SocialLinks',

  props: {
    spacingClass: {
      type: String,
      default: 'space-x-3'
    }
  },

  computed: {
    compSocialNetworks() {
      return [
        {
          key: 'instagram',
          url: 'https://www.instagram.com/pawpaddock/',
          icon: faInstagram
        },
        {
          key: 'facebook',
          url: 'https://www.facebook.com/pawpaddock',
          icon: faFacebook
        },
        {
          key: 'twitter',
          url: 'https://www.twitter.com/pawpaddock',
          icon: faTwitter
        }
      ]
    }
  }
}
