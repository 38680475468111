import dayjs from 'dayjs'
import { merge } from 'lodash'

import appVersion from '~/lib/appVersion'
import { platform } from '~/plugins/native/capacitor'

export default function (existingModel = {}) {
  return {
    model: merge(
      {
        message: null,
        data: null,
        level: 'debug',
        guestId: null,
        platform,
        appVersion: appVersion.default,
        createdAt: dayjs().toISOString()
      },
      existingModel
    )
  }
}
