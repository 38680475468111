import { isPlainObject } from 'lodash'
import safeJsonStringify from 'safe-json-stringify'

import LogEntity from '~/lib/entities/log'

export default function ({ $sentry, $Api, app, store, $cookies, $date }, inject) {
  const STORAGE_KEY_GUEST_ID = 'guest-id'

  // The reason we use our own logging function is to perform additional
  // calls like logging to Sentry
  class Log {
    constructor() {
      this.guestId = null

      const previousGuestId = $cookies.get(STORAGE_KEY_GUEST_ID)

      if (!$cookies.get(STORAGE_KEY_GUEST_ID)) {
        this.generateGuestId()
      } else {
        this.guestId = previousGuestId
      }

      store.commit('logs/setGuestId', this.guestId)
    }

    error(message, error, save = true) {
      console.error(message, error)

      if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
        $sentry.captureException(error)
      }

      if (process.env.APP_ANALYTICS_ENABLED === 'true' && app.$sessionRecording) {
        app.$sessionRecording.addError({
          message,
          error: error?.message
        })
      }

      if (save) {
        this.addToQueue(message, error, 'error')
      }
    }

    debug(message, data, save = true) {
      if (typeof data === 'undefined') {
        console.log(message)
      } else {
        console.log(message, data)
      }

      if (save) {
        this.addToQueue(message, data, 'debug')
      }
    }

    warn(message, data, save = true) {
      if (typeof data === 'undefined') {
        console.warn(message)
      } else {
        console.warn(message, data)
      }

      if (save) {
        this.addToQueue(message, data, 'warn')
      }
    }

    admin(message, data, save = true) {
      if (typeof data === 'undefined') {
        console.log(message)
      } else {
        console.log(message, data)
      }

      if (save) {
        this.addToQueue(message, data, 'admin')
      }
    }

    addToQueue(message, data, level) {
      // If error type then convert to string first
      data = Object.prototype.toString.call(data) === '[object Error]' ? data.toString() : data

      data = isPlainObject(data) ? { ...data } : Array.isArray(data) ? [...data] : data

      data = safeJsonStringify(data)

      message = isPlainObject(message) ? JSON.stringify(message) : message

      // Only JSON stringify if data isn't a primitive value
      store.commit(
        'logs/addToQueue',
        new LogEntity({
          message,
          data,
          level,
          guestId: this.guestId
        }).model
      )
    }

    generateGuestId() {
      // A guest ID allows us to associate authed users with their previous guest actions
      this.guestId = Date.now().toString(36) + Math.random().toString(36).substring(2)

      $cookies.set(STORAGE_KEY_GUEST_ID, this.guestId, {
        expires: $date().add(365, 'day').toDate(),
        path: '/'
      })

      this.debug('Guest ID created', this.guestId)
    }

    resetGuestId() {
      this.generateGuestId()
      store.commit('logs/setGuestId', this.guestId)
    }
  }

  // Inject to context as $log
  inject('log', new Log())
}
