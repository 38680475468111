export default function (existingModel = {}) {
  return {
    model: {
      email: existingModel.email || ''
    },
    validation: {
      email: { required: true, email: true }
    }
  }
}
