import { PlatformApi } from '~/services/api/platform'

export class ContentService extends PlatformApi {
  async getAllTags() {
    await this.get(`/content/tags`)
  }

  async getAllPublishedContent(type) {
    await this.get(`/content/published?type=${type}`)
  }

  async getAllContent(type) {
    await this.get(`/content/all?type=${type}`)
  }

  async getPublishedContentById(id) {
    await this.get(`/content/published/${id}`)
  }

  async create() {
    await this.post(`/content`)
  }

  async getById(id) {
    await this.get(`/content/${id}`)
  }

  async saveOrder(type, order) {
    await this.put('/content/order', { type, order })
  }

  async update(id) {
    await this.put(`/content/${id}`)
  }

  async unpublish(id) {
    await this.put(`/content/${id}`, {
      ...this.state.model,
      isPublished: false
    })
  }

  async publish(id) {
    await this.put(`/content/${id}`, {
      ...this.state.model,
      isPublished: true
    })
  }

  async deleteContent(id) {
    await this.delete(`/content/${id}`)
  }

  async fetchTotals() {
    await this.get(`/content/totals`)
  }

  async getDoc(type) {
    await this.get(`/content/doc/${type}`)
  }

  async saveDoc(type) {
    await this.put(`/content/doc/${type}`)
  }
}
