import { PlatformApi } from '~/services/api/platform'

export class SessionRecordingService extends PlatformApi {
  async sendEvents(replayId, body) {
    await this.post(`/session-recording/${replayId}/event`, body)
  }

  async fetchAllForUser(userId) {
    return await this.get(`/session-recording/user/${userId}`)
  }
}
