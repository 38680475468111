import ApiCore from '~/plugins/api/core'
import {
  AnnouncementService,
  AuthService,
  BookingService,
  ContentService,
  DiscountService,
  FileService,
  GeoService,
  MaintenanceService,
  NotificationService,
  OrganisationService,
  PaddockService,
  ReportingService,
  SessionRecordingService,
  SystemService,
  UserService
} from '~/services'
import { PlatformApi } from '~/services/api/platform'

export default function (nuxtContext, inject) {
  const api = {
    raw: apiModel => new ApiCore(nuxtContext, apiModel),
    default: apiModel => new PlatformApi(nuxtContext, apiModel),
    auth: apiModel => new AuthService(nuxtContext, apiModel),
    system: apiModel => new SystemService(nuxtContext, apiModel),
    user: apiModel => new UserService(nuxtContext, apiModel),
    organisation: apiModel => new OrganisationService(nuxtContext, apiModel),
    reporting: apiModel => new ReportingService(nuxtContext, apiModel),
    notification: apiModel => new NotificationService(nuxtContext, apiModel),
    file: apiModel => new FileService(nuxtContext, apiModel),
    geo: apiModel => new GeoService(nuxtContext, apiModel),
    content: apiModel => new ContentService(nuxtContext, apiModel),
    paddock: apiModel => new PaddockService(nuxtContext, apiModel),
    booking: apiModel => new BookingService(nuxtContext, apiModel),
    discount: apiModel => new DiscountService(nuxtContext, apiModel),
    maintenance: apiModel => new MaintenanceService(nuxtContext, apiModel),
    announcement: apiModel => new AnnouncementService(nuxtContext, apiModel),
    sessionRecording: apiModel => new SessionRecordingService(nuxtContext, apiModel)
  }

  // Inject to context as $api
  inject('api', api)
}
