import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=5a376758&scoped=true"
import script from "./Link.vue?vue&type=script&lang=js"
export * from "./Link.vue?vue&type=script&lang=js"
import style0 from "./Link.vue?vue&type=style&index=0&id=5a376758&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a376758",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/home/runner/work/platform/platform/packages/client/src/components/utils/loader/Loader.vue').default})
