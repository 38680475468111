import { cloneDeep, snakeCase } from 'lodash'

// Recursively remove null, undefined, empty objects and empty arrays
export function cleanEmpty(dirtyObject) {
  let item

  if (Array.isArray(dirtyObject)) {
    item = dirtyObject.map(x => cleanEmpty(x)).filter(value => value !== undefined)
    return item.length ? item : undefined
  } else if (dirtyObject && typeof dirtyObject === 'object') {
    item = {}
    Object.keys(dirtyObject).forEach(key => {
      const value = cleanEmpty(dirtyObject[key])

      if (value !== undefined) {
        item[key] = value
      }
    })
    return Object.keys(item).length ? item : undefined
  } else {
    return dirtyObject === null ? undefined : dirtyObject
  }
}

export function truncateObjectStrings(targetObject, length = 500) {
  const item = cloneDeep(targetObject)

  if (targetObject && typeof targetObject === 'object') {
    Object.keys(targetObject).forEach(key => {
      const value = truncateObjectStrings(targetObject[key])

      if (value !== undefined) {
        if (typeof value === 'string' && value.length > length) {
          item[key] = value.slice(0, length + 1)
        } else {
          item[key] = value
        }
      }
    })
  }

  return item
}

export function snakeCaseKeys(value) {
  return alterCollectionKeys(value, snakeCase)
}

function alterCollectionKeys(input, keyModifier) {
  if (typeof input !== 'object' || input === null) {
    return input
  }

  if (Array.isArray(input)) {
    return input.map(value => alterCollectionKeys(value, keyModifier))
  }

  return Object.keys(input).reduce(function (newObject, key) {
    const value = input[key]
    const newValue = typeof value === 'object' && value !== null ? alterCollectionKeys(value, keyModifier) : value

    newObject[keyModifier(key)] = newValue
    return newObject
  }, {})
}
