import { storePersist } from '~/lib/storePersist'
import { isNative } from '~/plugins/native/capacitor'

export default async function ({ $log, $ability, store, route, $native, $network, req, redirect }) {
  // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
  if (route.path.includes('/maintenance') && !route.path.includes('/admin/')) {
    $native.hideSplashScreen()

    return
  }

  if (route.query.accessToken) {
    $log.debug('Auth: Access token in URL')

    store.dispatch('auth/handleTokens', {
      accessToken: route.query.accessToken,
      refreshToken: route.query.refreshToken
    })

    store.commit('auth/setSocialProviderRedirectingTo', null)

    return redirect({ ...route, query: { ...route.query, accessToken: undefined, refreshToken: undefined } })
  }

  let app = store.getters['app/getApp']

  if (!app.isClientLoaded && isNative) {
    $log.debug('Native: Client not loaded yet')

    await storePersist.load(store)
    await store.dispatch('auth/loadExistingTokens')

    app = store.getters['app/getApp']
  }

  if (!app.isReady) {
    try {
      const pingResponse = await $network.checkConnection(true)

      // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
      if (pingResponse.isMaintenanceModeEnabled) {
        return
      }
    } catch (error) {
      // Stub - internet offline
    }

    await store.dispatch('auth/loadExistingTokens')
    store.dispatch('auth/loadExistingOrganisationId')

    await store.dispatch('app/fetchStatus')

    // Re-fetch the app in case it was updated
    app = store.getters['app/getApp']
  }

  // Have we fetched our system status yet? Usually page load only
  // On native we cache the org details for a faster startup time
  if (!app.isReady) {
    await store.dispatch('app/fetchStatus')

    app = store.getters['app/getApp']
  }

  store.dispatch('app/fetchGlobalBanner')

  // Don't change the status bar colour if we are still on the login page
  if (isNative && app.isAuth) {
    store.dispatch('device/changeStatusBarColorPrimary', { root: true })
  }

  $native.hideSplashScreen()

  if (!route.path.includes('/shop') && 'Shoprocket' in window) {
    window.Shoprocket.destroy()
  }

  if (app.isNative) {
    if (route.path.startsWith('/shop')) {
      return redirect('/platform/shop')
    }

    if (route.path.startsWith('/news')) {
      return redirect('/platform/news')
    }
  }

  const authedPaths = ['/platform', '/admin', '/account']

  // If user isn't logged in redirect them to login page if the current path isn't a guest path
  if (!app.isAuth && authedPaths.find(authPath => route.path.startsWith(authPath))) {
    store.commit('navigation/setUnauthenticatedPath', route.fullPath)

    $log.debug('Trying to access a protected route without being logged in', route.fullPath)

    return redirect('/auth/logout')
  }

  if (app.isAuth && store.state.navigation.originalUnauthenticatedPath) {
    // If the user was previously trying to get somewhere let's redirect them to that path
    const originalPath = store.state.navigation.originalUnauthenticatedPath

    store.commit('navigation/setUnauthenticatedPath', null)

    return redirect(originalPath)
  }

  // Admin visiting dashboard by accident
  if (route.path.startsWith('/platform') && app.isAuth && app.isStaff) {
    return redirect('/admin')
  }

  // User visiting admin
  if (route.path.startsWith('/admin') && app.isAuth && !app.isStaff) {
    return redirect('/platform')
  }

  // Only go to platform if we are staff or native
  if (route.path === '/' && (app.isStaff || app.isNative)) {
    return app.isStaff ? redirect('/admin') : redirect('/platform')
  }

  // If platform route and we aren't native, rewrite to account
  if (route.path.startsWith('/platform') && !app.isNative) {
    return redirect(route.path.replace('/platform', '/account'))
  }

  if (route.path.startsWith('/account') && app.isNative) {
    return redirect(route.path.replace('/account', '/platform'))
  }
}
