export const colorsArray = [
  'green',
  'indigo',
  'yellow',
  'purple',
  'red',
  'gray',
  'orange',
  'blue',
  'light-blue',
  'black',
  'primary',
  'secondary'
]

// Reusable color prop
export const colorProp = {
  type: String,
  default: 'gray',
  validator: prop => colorsArray.includes(prop)
}
