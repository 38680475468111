import scrollIntoView from 'scroll-into-view-if-needed'

const $body = document.querySelector('body')
let scrollPosition = 0

export default function ({ $log }, inject) {
  const scroll = {
    toTop: element => {
      const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style

      // Because of 100% on the main wrapper (sidebar sticky issues) we need to scroll the real scrollable element
      // LayoutPlatformShell contains this, be sure to add the ID to any future non admin layouts you create

      let scrollElement =
        element ??
        (window.innerWidth < 768
          ? document.getElementById('scroll-container-mobile')
          : document.getElementById('scroll-container'))

      if (!scrollElement) {
        scrollElement = window
      }

      if (isSmoothScrollSupported) {
        // Native smooth scrolling
        scrollElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        // Old way scrolling without effects and legacy 2 params required
        scrollElement.scrollTo(0, 0)
      }
    },

    intoViewIfNeeded: (node, always = false, block = 'center', boundary) => {
      // Block Defines vertical alignment. One of start, center, end, or nearest.

      if (node) {
        scrollIntoView(node, {
          scrollMode: always ? 'always' : 'if-needed',
          block,
          inline: 'center',
          behavior: 'smooth',
          boundary: boundary ?? undefined
        })
      }
    },

    toElementAdjusted: (element, offset, scrollContainer) => {
      const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style

      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - offset

      let scrollElement =
        scrollContainer ??
        (window.innerWidth < 768
          ? document.getElementById('scroll-container-mobile')
          : document.getElementById('scroll-container'))

      if (!scrollElement) {
        scrollElement = window
      }

      if (isSmoothScrollSupported) {
        scrollElement.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      } else {
        scrollElement.scrollTo(0, offsetPosition)
      }
    },

    modalToBottom: () => {
      const element = document.querySelector('.modal.side')

      if (element) {
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' })
      }
    },

    lock: () => {
      scrollPosition = window.pageYOffset
      $body.style.overflow = 'hidden'
      $body.style.position = 'fixed'
      $body.style.top = `-${scrollPosition}px`
      $body.style.width = '100%'

      const isDesktop = document.getElementById('app')
        ? document.getElementById('app').classList.contains('mdAndUp')
        : false

      if (isDesktop) {
        $body.style.paddingRight = '15px'
      }
    },

    unlock: () => {
      $body.style.removeProperty('overflow')
      $body.style.removeProperty('position')
      $body.style.removeProperty('top')
      $body.style.removeProperty('width')
      $body.style.removeProperty('padding-right')

      window.scrollTo(0, scrollPosition)
    }
  }

  // Inject to context as $scroll
  inject('scroll', scroll)
}
