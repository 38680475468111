import { render, staticRenderFns } from "./Flag.vue?vue&type=template&id=63611c7f&scoped=true"
import script from "./Flag.vue?vue&type=script&lang=js"
export * from "./Flag.vue?vue&type=script&lang=js"
import style0 from "./Flag.vue?vue&type=style&index=0&id=63611c7f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63611c7f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/home/runner/work/platform/platform/packages/client/src/components/elements/base/image/Img.vue').default})
