export const orderStatus = {
  PENDING: 'pending',
  PAID: 'paid',
  PARTIALLY_REFUNDED: 'partially-refunded',
  REFUNDED: 'refunded',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  EXPIRED: 'expired'
}

export const activeOrderStatuses = [orderStatus.PAID, orderStatus.PARTIALLY_REFUNDED]

export const bookingStatus = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  RESCHEDULED: 'rescheduled',
  REFUNDED: 'refunded'
}

export const bookingStatusConfig = {
  PENDING: {
    value: bookingStatus.PENDING,
    color: 'yellow',
    translationKey: 'pending'
  },
  CONFIRMED: {
    value: bookingStatus.CONFIRMED,
    color: 'green',
    translationKey: 'confirmed'
  },
  RESCHEDULED: {
    value: bookingStatus.RESCHEDULED,
    color: 'gray',
    translationKey: 'rescheduled'
  },
  REFUNDED: {
    value: bookingStatus.REFUNDED,
    color: 'blue',
    translationKey: 'refunded'
  }
}

export function findBookingConfigByValue(value) {
  return Object.values(bookingStatusConfig).find(status => status.value === value)
}

export const maintenanceJobStatus = {
  OPEN: 'open',
  CLOSED: 'closed'
}

export const announcementStatus = {
  DRAFT: 'draft',
  PENDING: 'pending',
  SENDING: 'sending',
  COMPLETE: 'complete',
  FAILED: 'failed'
}

export const announcementLogStatus = {
  PENDING: 'pending',
  SENT: 'sent',
  COMPLETE: 'complete',
  FAILED: 'failed'
}

export const announcementStatusConfig = {
  DRAFT: {
    value: announcementStatus.DRAFT,
    color: 'gray',
    translationKey: 'draft'
  },
  PENDING: {
    value: announcementStatus.PENDING,
    color: 'yellow',
    translationKey: 'pending'
  },
  SENDING: {
    value: announcementStatus.SENDING,
    color: 'blue',
    translationKey: 'sending'
  },
  COMPLETE: {
    value: announcementStatus.COMPLETE,
    color: 'green',
    translationKey: 'complete'
  },
  FAILED: {
    value: announcementStatus.FAILED,
    color: 'red',
    translationKey: 'failed'
  }
}

export function findAnnouncementConfigByValue(value) {
  return Object.values(announcementStatusConfig).find(status => status.value === value)
}
