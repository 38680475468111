export default function ({ $modal, app }, inject) {
  const confirm = ({ title, description, postamble, iframeUrl, cancelButtonLabel, confirmButtonLabel } = {}) => {
    return new Promise((resolve, reject) => {
      $modal.open(
        'global/confirm',
        {
          title,
          description,
          postamble,
          iframeUrl,
          cancelButtonLabel,
          confirmButtonLabel
        },
        response => {
          // We decided to resolve the promise on cancel rather than reject because it would mean a
          // lot of empty catches as we often don't want to take any action if they cancel the action
          resolve(response.hasConfirmed)
        }
      )
    })
  }

  // Inject to context as $confirm
  inject('confirm', confirm)
}
