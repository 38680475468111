import { differenceBy, get, orderBy, uniqBy } from 'lodash'
import { bookingStatus } from 'pawpaddock-common/data/status'
import { set } from 'vue'

import { ApiModel } from '~/plugins/api/model'
import { dateNew } from '~/plugins/date'

const initialState = () => {
  return {
    basket: {},
    fetchBasketApi: new ApiModel(),
    bookings: [],
    previousBookings: [],
    fetchBookingsApi: new ApiModel(),
    fetchPreviousBookingsApi: new ApiModel(),
    creditBalance: 0,
    fetchCreditBalanceApi: new ApiModel(),
    discountCode: null,
    isPurchasing: false
  }
}

export const state = () => initialState()

export const getters = {
  getBasket: state => {
    // Group by paddock id
    const basket = { bookings: {}, vouchers: [] }

    basket.bookings = state.basket.bookings.reduce((acc, booking) => {
      const key = booking.paddock.name

      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push({ ...booking, basketType: 'booking' })

      // Order by startAt
      acc[key] = orderBy(acc[key], ['startAt'], ['asc'])

      return acc
    }, {})

    // Add vouchers
    state.basket.vouchers.forEach(voucher => {
      basket.vouchers.push({ ...voucher, basketType: 'voucher' })
    })

    return basket
  },

  totalItemsInBasket: state => {
    return 'bookings' in state.basket ? state.basket.bookings.length + state.basket.vouchers.length : 0
  },

  totalBookingsInBasket: state => {
    return 'bookings' in state.basket ? state.basket.bookings.length : 0
  },

  totalPriceInBasket: state => {
    const bookingValue = state.basket?.bookings.reduce((acc, item) => {
      return acc + item.price
    }, 0)

    const voucherValue = state.basket?.vouchers.reduce((acc, item) => {
      return acc + item.value
    }, 0)

    return bookingValue + voucherValue
  },

  getActiveBookings: state => {
    return state.bookings
      .filter(
        booking =>
          dateNew.fromSQL(booking.startAt) > dateNew.now().startOf('day') &&
          booking.status === bookingStatus.CONFIRMED
      )
      .sort((a, b) => dateNew.fromSQL(a.startAt).diff(dateNew.fromSQL(b.startAt)).toObject().milliseconds)
  },

  getPreviousBookings: state => {
    // Get all bookings before yesterday
    return (state.previousBookings || [])
      .filter(
        booking =>
          dateNew.fromSQL(booking.startAt) < dateNew.now().startOf('day') ||
          [bookingStatus.RESCHEDULED, bookingStatus.REFUNDED].includes(booking.status)
      )
      .sort((a, b) => dateNew.fromSQL(b.startAt).diff(dateNew.fromSQL(a.startAt)).toObject().milliseconds)
  },

  totalActiveBookings: (state, getters) => {
    return getters.getActiveBookings.length
  },

  totalPreviousBookings: (state, getters) => {
    return getters.getPreviousBookings.length
  },

  creditBalance: state => {
    return state.creditBalance
  },

  hasCreditBalance: (state, getters) => {
    return getters.creditBalance > 0
  },

  lastBookedPaddock: (state, getters) => {
    return orderBy(state.bookings, 'createdAt', 'desc')[0]?.paddock
  },

  previousPaddocks: state => {
    let previousPaddocks = []

    // Get unique paddock names from previous bookings
    state.bookings.forEach(booking => {
      previousPaddocks.push(booking.paddock)
    })

    // Remove duplicates
    previousPaddocks = uniqBy(previousPaddocks, 'id')

    return previousPaddocks
  }
}

export const actions = {
  async fetchBasket({ state, commit }) {
    try {
      await this.$api.booking(state.fetchPaddocksApi).useStorePath('booking.fetchBasketApi').getBasket()

      commit('setBasket', { basket: get(state.fetchBasketApi, 'response.data', {}) })
    } catch (error) {
      this.$log.debug('Error fetching basket', error)
    }
  },

  resetBasket({ commit }) {
    this.$log.debug('Resetting basket')

    commit('setBasket', { basket: {}, checkForRemovals: false })
    commit('setDiscountCode', null)
  },

  async fetchRecentBookings({ state, commit, rootGetters }) {
    if (!rootGetters['app/getApp'].isAuth) {
      return false
    }

    try {
      await this.$api.booking(state.fetchBookingsApi).useStorePath('booking.fetchBookingsApi').getRecentBookings()

      commit('setBookings', get(state.fetchBookingsApi, 'response.data', []))
    } catch (error) {
      this.$log.debug('Error fetching active bookings', error)
    }
  },

  async fetchPreviousBookings({ state, commit, rootGetters }) {
    if (!rootGetters['app/getApp'].isAuth) {
      return false
    }

    try {
      await this.$api
        .booking(state.fetchPreviousBookingsApi)
        .useStorePath('booking.fetchPreviousBookingsApi')
        .getPreviousBookings()

      commit('setPreviousBookings', get(state.fetchPreviousBookingsApi, 'response.data', []))
    } catch (error) {
      this.$log.debug('Error fetching previous bookings', error)
    }
  },

  async fetchCreditBalance({ state, commit }) {
    try {
      await this.$api
        .booking(state.fetchCreditBalanceApi)
        .useStorePath('booking.fetchCreditBalanceApi')
        .getCreditBalance()

      commit('setCreditBalance', state.fetchCreditBalanceApi.response.data?.balance || 0)
    } catch (error) {
      // STUB
    }
  }
}

export const mutations = {
  setBasket(state, { basket, checkForRemovals = true }) {
    const removedSessions = differenceBy(state.basket.bookings, basket.bookings, 'id')

    if (checkForRemovals && removedSessions.length > 0) {
      this.$modal.open('book/expired', removedSessions)
    }

    set(state, 'basket', basket)
  },

  setBookings(state, bookings) {
    set(state, 'bookings', bookings)
  },

  setPreviousBookings(state, bookings) {
    set(state, 'previousBookings', bookings)
  },

  setCreditBalance(state, balance) {
    set(state, 'creditBalance', balance)
  },

  setDiscountCode(state, discountCode) {
    this.$log.debug('Discount code applied', discountCode)
    state.discountCode = discountCode
  },

  setIsPurchasing(state, isPurchasing) {
    state.isPurchasing = isPurchasing
  },

  reset(state) {
    this.$log.debug('Resetting basket module')

    Object.assign(state, initialState())
  }
}
