import { PlatformApi } from '~/services/api/platform'

export class BookingService extends PlatformApi {
  async getAvailability(params) {
    if (!params?.paddockId) {
      return null
    }

    const request = await this.get(`/booking/availability`, params)

    return request.response.data
  }

  async book() {
    return await this.post('/booking/book')
  }

  async createBlockBooking() {
    return await this.post(`/booking/admin/block`)
  }

  async getBasket() {
    return await this.get('/booking/basket')
  }

  async deleteBooking(id) {
    return await this.delete(`/booking/basket/${id}`)
  }

  async checkout() {
    return await this.post(`/booking/checkout`)
  }

  async getExistingCards() {
    return await this.get(`/booking/cards`)
  }

  async payWithExistingCard(orderId, cardId) {
    return await this.post(`/booking/pay/${orderId}/card/${cardId}`)
  }

  async deleteCard(cardId) {
    return await this.delete(`/booking/cards/${cardId}`)
  }

  async completeFreeOrder(orderId) {
    return await this.post(`/booking/pay/${orderId}/free`)
  }

  async updateRememberCard(orderId) {
    return await this.put(`/booking/checkout/${orderId}`)
  }

  async updateCheckoutBannedBreed(orderId) {
    return await this.put(`/booking/checkout/${orderId}/banned-breed`)
  }

  async completeAdminOrder(orderId) {
    return await this.post(`/booking/pay/${orderId}/admin`)
  }

  async fetchOrder(id) {
    return await this.get(`/booking/order/${id}`)
  }

  async fetchOrderAdmin(id) {
    return await this.get(`/booking/order/admin/${id}`)
  }

  async getRecentBookings() {
    return await this.get(`/booking/recent`)
  }

  async getPreviousBookings() {
    return await this.get(`/booking/previous`)
  }

  async getBookingAdmin(id) {
    return await this.get(`/booking/admin/${id}`)
  }

  async getBooking(id) {
    return await this.get(`/booking/${id}`)
  }

  async getUserBookingsAdmin(userId) {
    return await this.get(`/booking/admin/user/${userId}`)
  }

  async getUserBookingStatsAdmin(userId) {
    return await this.get(`/booking/admin/user/${userId}/stats`)
  }

  async rescheduleBookingAdmin(id) {
    return await this.put(`/booking/admin/${id}/reschedule`)
  }

  async rescheduleBooking(id) {
    return await this.put(`/booking/${id}/reschedule`)
  }

  async getNotificationsAdmin(contextIds) {
    return await this.post(`/booking/admin/notifications`, { contextIds })
  }

  async cancelBookingAdmin(id) {
    return await this.put(`/booking/admin/${id}/cancel`)
  }

  async cancelBooking(id) {
    return await this.put(`/booking/${id}/cancel`)
  }

  async getCreditBalance() {
    return await this.get(`/booking/credit-balance`)
  }

  async getCreditAdmin(userId) {
    return await this.get(`/booking/admin/user/${userId}/credit`)
  }

  async saveCreditAdjustmentAdmin({ userId, amount, notes }) {
    return await this.post(`/booking/admin/user/${userId}/credit`, { amount, notes })
  }

  async getAllBookings(params) {
    return await this.get(`/booking`, params)
  }

  async getUserFinancials(userId) {
    return await this.get(`/booking/financials/${userId}`)
  }

  async getWishlist() {
    return await this.get(`/booking/wishlist`)
  }

  async addWishListDate({ date, paddockId }) {
    return await this.post(`/booking/wishlist`, { date, paddockId })
  }

  async removeWishListDate({ date, paddockId }) {
    return await this.delete(`/booking/wishlist`, { date, paddockId })
  }
}
