import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/vue'
import { posthog } from 'posthog-js'
import Vue from 'vue'

export default ({ app, $log, $appVersion }, inject) => {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    enabled: process.env.APP_ERROR_REPORTING_ENABLED === 'true',
    release: $appVersion,
    environment: process.env.APP_ENVIRONMENT,
    trackComponents: true,
    ignoreErrors: [
      'Not Authorized',
      'Unauthorised',
      'Non-Error promise rejection captured',
      'Request aborted',
      'Network Error',
      'Failed to initialize WebGL',
      'Bad HTTP status',
      'TypeError: Internal error',
      'timeout of 300000ms exceeded',
      'Fetch is aborted',
      /LngLatLike/,
      /Loading chunk/,
      'ChunkLoadError',
      'response_error',
      'Request failed with status code 409',
      'Error: undefined',
      'TypeError: Load failed',
      'Failed to fetch',
      'Item with given key does not exist',
      'Failed to fetch feature flags from PostHog.',
      'Your card has insufficient funds.',
      'Move target is disabled',
      'Redirected when going from "/" to "/platform" via a navigation guard.',
      "PostHog failed to start performance observer TypeError: Cannot read property 'filter' of undefined",
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    integrations: [
      new CaptureConsoleIntegration({ levels: ['error'] }),
      new posthog.SentryIntegration(posthog, process.env.SENTRY_ORG_NAME, process.env.SENTRY_PROJECT_ID)
    ]
  })

  // Inject to context as $sentry
  inject('sentry', Sentry)
}
