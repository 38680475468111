import { PlatformApi } from '~/services/api/platform'

export class AnnouncementService extends PlatformApi {
  async sendTest(templateId) {
    await this.post(`/announcement/test`, { templateId })
  }

  async sendAnnouncement(id) {
    await this.post(`/announcement/${id}/send`)
  }

  async save(id) {
    if (id) {
      return await this.put(`/announcement/${id}`)
    } else {
      return await this.post(`/announcement`)
    }
  }

  async getTemplate(templateId) {
    return await this.get(`/announcement/template/${templateId}`)
  }

  async formStats(model) {
    return await this.post(`/announcement/form-stats`, model)
  }

  async saveGlobalBanner(model) {
    return await this.post(`/announcement/global-banner`, model)
  }

  async getGlobalBanner() {
    return await this.get(`/announcement/global-banner`)
  }
}
