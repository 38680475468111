import { PlatformApi } from '~/services/api/platform'

export class AuthService extends PlatformApi {
  async login(postModel) {
    return await this.post(`/auth/login`, postModel)
  }

  async logout() {
    return await this.post(`/auth/logout`)
  }

  async loginSocial(provider, accessToken, meta) {
    return await this.post(`/auth/login/social/${provider}`, { accessToken, meta })
  }

  async registerUser() {
    return await this.post(`/auth/register/user`)
  }

  async forgotPassword(postModel) {
    return await this.post(`/auth/forgot-password`, postModel)
  }

  async resetPassword(postModel) {
    return await this.post(`/auth/reset-password`, postModel)
  }

  async verifyResetPasswordCode(verificationCode) {
    return await this.post(`/auth/verify-reset-password-code`, { verificationCode })
  }

  async changePassword(postModel) {
    return await this.post(`/auth/change-password`)
  }

  async refreshToken(refreshToken) {
    return await this.post(`/auth/refresh`, { refreshToken })
  }

  async acceptInvite() {
    return await this.put(`/auth/invite-accept`)
  }

  async verifyEmail() {
    return await this.post(`/auth/verify-email`)
  }

  async resendVerificationEmail() {
    return await this.post(`/auth/resend-verification-email`)
  }
}
