
import { faChevronLeft, faRotateRight } from '@fortawesome/pro-regular-svg-icons'
import { faCircleQuestion, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'Error',

  layout: 'default',

  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      faChevronLeft,
      faCircleQuestion,
      faTriangleExclamation,
      faRotateRight
    }
  },

  computed: {
    compError() {
      const errorConfig = {
        title: '',
        buttonLabel: this.$t('backToHomepage'),
        buttonTo: '/',
        icon: faCircleQuestion,
        primaryError: '',
        secondaryError: ''
      }

      if (this.error.statusCode === 404) {
        errorConfig.title = this.$t('sorryWeCouldntFindThisPage')
        errorConfig.primaryError = `${this.$t('route')}: ${this.$route.fullPath}`
        errorConfig.secondaryError = `${this.$t('base')}: ${this.$router.options.base}`
      } else {
        errorConfig.icon = faTriangleExclamation
        errorConfig.title = this.$t('sorryErrorHasOccured')
        errorConfig.subTitle = this.$t('ourDevelopmentTeamAlertedOfError')
        errorConfig.primaryError = this.error.message
          ? this.error.message
          : `${this.$t('error')} ${this.error.statusCode}`
        errorConfig.secondaryError = `${this.$t('base')}: ${this.$router.options.base}`
      }

      return errorConfig
    },

    compHero() {
      return {
        title: 'Page not found',
        image: require('~/assets/images/backgrounds/voucher-hero.jpg'),
        alt: '3 dogs in a paddock'
      }
    },

    compIsPageNotFound() {
      return this.error.statusCode === 404
    }
  },

  created() {
    if (this.error.statusCode === 404) {
      this.$api.system(this.notFoundApi).pageNotFound(this.$route.fullPath)

      this.$log.debug('Page not found', this.$route.fullPath)
    }
  },

  methods: {
    goBack() {
      this.$redirect.back()
    },

    onBackToHomepage(to) {
      // If we error on the homepage we can't navigate there, so let's reload instead
      if (to === '/' && this.$route.path === '/') {
        window.location.reload()
      }
    },

    reload() {
      window.location.reload()
    }
  }
}
