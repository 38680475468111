
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'NotificationFloating',
  props: {
    type: {
      type: String,
      default: 'information',
      validator: prop => ['information', 'warning', 'error', 'success'].includes(prop)
    },

    isIconVisible: {
      type: Boolean,
      default: true
    },

    timeoutMs: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      faTimes
    }
  },

  computed: {
    compIcon() {
      let icon = faInfoCircle

      switch (this.type) {
        case 'warning':
          icon = faExclamationTriangle
          break

        case 'error':
          icon = faTimesCircle
          break

        case 'success':
          icon = faCheckCircle
          break
      }

      return icon
    }
  },

  methods: {
    dismiss() {
      this.$emit('dismiss', this.name)
    }
  }
}
