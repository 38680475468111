import { PlatformApi } from '~/services/api/platform'

export class NotificationService extends PlatformApi {
  async getActivityById(notificationId) {
    return await this.get(`/notification/${notificationId}`)
  }

  async savePushToken(token) {
    return await this.post('/notification/token', {
      type: 'push',
      token
    })
  }

  async getPushTokens() {
    return await this.get('/notification/token')
  }

  async getPreferences() {
    return await this.get('/notification/preferences')
  }

  async savePreferences(preferences) {
    return await this.put('/notification/preferences', { preferences })
  }
}
