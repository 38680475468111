import { platform } from '~/plugins/native/capacitor'

export default ({ app, $log, route, store }, inject) => {
  const liveChat = {
    // We want to delay loading live chat as it may never be used
    // Be sure to call setup from anywhere you show the live chat button
    // Ideally we won't want to load this until we are sure the user actually wants to live chat
    setup: () => {
      return new Promise((resolve, reject) => {
        const existingElement = document.getElementById('chat-widget-embed')

        if (existingElement && window.SI_API) {
          const app = store.getters['app/getApp']

          if (app.isAuth) {
            window.SI_API.setChatInfo(app.user?.fullName, app.user?.email, app.user?.mobileNumber)

            window.SI_API.addParams([{ name: 'User ID', value: app.user?.id }])
          }

          window.SI_API.showPopup()

          resolve()
        }

        const script = document.createElement('script')

        script.id = 'chat-widget-embed'
        script.src = 'https://www.socialintents.com/api/chat/socialintents.1.3.js#2c9faa3580b0e5450180b9c651d20a43'
        script.async = true

        document.head.appendChild(script)

        window.onSIApiReady = function () {
          $log.debug('Live chat ready')

          window.SI_API.onChatEnded = function () {
            window.SI_API.hideTab()
          }

          const app = store.getters['app/getApp']

          const chatParams = []

          if (app.isAuth) {
            window.SI_API.setChatInfo(app.user?.name, app.user?.email, app.user?.mobileNumber)

            chatParams.push({ name: 'User ID', value: app.user?.id })
          } else if (store.state.auth.loginApi.model.email) {
            // Get info from any login forms
            window.SI_API.setChatInfo('', store.state.auth.loginApi.model.email)
          }

          chatParams.push({ name: 'Platform', value: platform })

          window.SI_API.addParams(chatParams)

          window.SI_API.showPopup()

          resolve()
        }
      })
    }
  }

  // Inject to context as $liveChat
  inject('liveChat', liveChat)
}
