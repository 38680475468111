import { FacebookLogin } from '@capacitor-community/facebook-login'

import { isNative, platform } from '~/plugins/native/capacitor'

// Called last when all other plugins have been initialised
export default function ({ app, store, $log, $sentry, $analytics, $i18n, $sessionRecording }) {
  store.dispatch('device/getInfo')

  // Every time the route changes (fired on initialization too)
  app.router.afterEach((to, from) => {
    store.commit('navigation/addRouteHistory', to)

    // Remove accessToken from URL before saving to logs
    const url = new URL(`https://example.com${to.fullPath}`)

    if (url.searchParams.has('accessToken')) {
      url.searchParams.set('accessToken', '***')
    }

    if (process.env.APP_ANALYTICS_ENABLED === 'true') {
      app.$sessionRecording.addPageView({
        message: 'New page',
        data: url.pathname + url.search
      })
    }

    $log.debug('New page', url.pathname + url.search)
  })

  window.onNuxtReady(() => {
    store.commit('app/setClientLoaded', true)

    if (!isNative) {
      FacebookLogin.initialize({ appId: process.env.FACBEOOK_APP_ID })
    }

    // Add additional debug information to our Sentry error reporting
    if (process.env.APP_ERROR_REPORTING_ENABLED === 'true') {
      $sentry.configureScope(scope => {
        scope.setTag('app-platform', platform)
        scope.setTag('app-native', isNative)
      })
    }

    if (process.env.APP_ANALYTICS_ENABLED === 'true') {
      $analytics.setUserProperty('platform', platform)
      $analytics.setUserProperty('locale', app.i18n.localeProperties.iso)
      $analytics.setUserProperty('isNative', isNative)

      $sessionRecording.setup()
    }
  })
}
