import { render, staticRenderFns } from "./DropdownMenuList.vue?vue&type=template&id=b58928ac&scoped=true"
import script from "./DropdownMenuList.vue?vue&type=script&lang=js"
export * from "./DropdownMenuList.vue?vue&type=script&lang=js"
import style0 from "./DropdownMenuList.vue?vue&type=style&index=0&id=b58928ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b58928ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Flag: require('/home/runner/work/platform/platform/packages/client/src/components/elements/geo/flag/Flag.vue').default})
