
export default {
  name: 'AppWrapper',

  data() {
    return {
      isClientMounted: false
    }
  },

  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })

    return {
      ...i18nHead,

      link: [
        ...i18nHead.link,
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: require('~/assets/images/logos/favicon.png')
        }
      ]
    }
  },

  mounted() {
    this.isClientMounted = true
  }
}
