import localec5b99526 from '../../locales/en-GB.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en-GB"},
  vueI18nLoader: false,
  locales: [{"code":"en-GB","name":"British English","iso":"en-GB","file":"en-GB.json","isCatchallLocale":true}],
  defaultLocale: "en-GB",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "../locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"all","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true,"syncLocale":true,"syncMessages":false},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  normalizedLocales: [{"code":"en-GB","name":"British English","iso":"en-GB","file":"en-GB.json","isCatchallLocale":true}],
  localeCodes: ["en-GB"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "G",
  6: "B",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "o",
  20: "c",
  21: "a",
  22: "l",
  23: "e",
  24: "s",
  25: "/",
  26: "e",
  27: "n",
  28: "-",
  29: "G",
  30: "B",
  31: ".",
  32: "j",
  33: "s",
  34: "o",
  35: "n",
  36: "\"",
  37: "}",
}

export const localeMessages = {
  'en-GB.json': () => Promise.resolve(localec5b99526),
}
